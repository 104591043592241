import React from "react";
import Select from "react-select";

export function JoinSelect(props) {
	const { className, span, labelfor, name, data, placeholder, onChange } =
		props;

	const handleSelectChange = selectedOption => {
		// 선택된 값이 있을 때 onChange 콜백 호출
		onChange && onChange({ target: { name, value: selectedOption } });
	};

	return (
		<div className={`selectBox ${className}`}>
			<div className="joinCompanyInput">
				<div className="InputBtw">
					<label>
						{labelfor}
						{span && <span className="star">{span}</span>}
					</label>
				</div>
			</div>
			<Select
				options={data}
				placeholder={placeholder}
				onChange={handleSelectChange}
			/>
		</div>
	);
}
