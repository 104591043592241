import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sampleData from "../../data/sampleData.js";
import { Rlrhdanfwhdfb, Qhcjfanfwhdfb } from "../../utils/postData";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const Grid = ({
	sortedPatients,
	setSortedPatients,
	allOptions,
	patientsDataBoxWidth,
	setPatientDataNav,
	patientDataNav,
	onPatientDataChange,
	layout,
	setLayout,
	selectedOption,
	dateRange,
	setChanges,
	dndStatus,
	qhcjfanfwhdfb,
	sidebarOpen,
	fullScreenHandle,
	hiddenOptions,
}) => {
	const [realData, setRealdata] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [isLayoutReady, setIsLayoutReady] = useState(false); // 새로운 상태 추가
	const [prosthodonticTypes, setProsthodonticTypes] = useState([]); // 기공물 종류
	const [writer, setWriter] = useState(""); // 작성자 상태 추가

	useEffect(() => {
		const initialStorageDentalLabs = JSON.parse(
			localStorage.getItem("initialStorageDentalLabs"),
		);
		if (initialStorageDentalLabs) {
			const writerName = initialStorageDentalLabs[2].writer.name;
			setWriter(writerName);
		}
	}, [writer]);
	// console.log("선택된 작성자", writer);
	const navigate = useNavigate();
	// 보철물 종류 데이터 정제
	const qhcjfanfResult = qhcjfanfwhdfb.reduce((acc, item) => {
		item.ProsthesisTypes.forEach(type => {
			acc.push({ ProsthesisTypeID: type.ProsthesisTypeID, name: type.Name });
		});
		return acc;
	}, []);

	// 정제한 데이터 순회
	const prosthesisTypeMap = qhcjfanfResult.reduce((acc, current) => {
		acc[current.ProsthesisTypeID] = current.name;
		return acc;
	}, {});

	function getProsthesisTypeNames(prosthesisTypeIds) {
		// 단일 ID를 배열로 취급하기 위해 Array.isArray를 사용하여 배열인지 확인합니다.
		// 배열이 아니면 배열로 변환합니다.
		const ids = Array.isArray(prosthesisTypeIds)
			? prosthesisTypeIds
			: [prosthesisTypeIds];
		// 각 ID에 대한 이름을 찾아서 배열로 만듭니다.
		const names = ids
			.map(id => prosthesisTypeMap[id])
			.filter(name => name !== undefined);
		// 찾아낸 이름들을 쉼표와 공백으로 구분하여 하나의 문자열로 합칩니다.
		return names.join(", ");
	}

	// id값 전달하는 함수
	const handleItemClick = postId => {
		navigate(`/post/${postId}`);
	};

	let selectRlrhdth = selectedOption[1];
	useEffect(() => {
		setIsLayoutReady(false); // 먼저 GridLayout의 렌더링을 지연시키기 위해 false로 설정합니다.
		const timer = setTimeout(() => {
			setIsLayoutReady(true); // 1200밀리초 후에 GridLayout를 렌더링하기 위해 true로 설정합니다.
		}, 1200);

		return () => clearTimeout(timer); // 컴포넌트가 언마운트되거나 selectRlrhdth가 다시 변경될 때 타이머를 클리어합니다.
	}, [selectRlrhdth]); // selectRlrhdth의 변경을 감지하기 위해 의존성 배열에 추가합니다.

	// allOptions가 변경될 때 patientDataNav.gridCols[0] 업데이트
	useEffect(() => {
		setPatientDataNav(prevState => ({
			...prevState,
			gridCols: [allOptions.length, 1, ""],
		}));
	}, [allOptions, setPatientDataNav, hiddenOptions]);

	// GridLayout 위치 바뀔 때마다 업데이트
	// const onLayoutChange = newLayout => {
	// 	if (patientDataNav.selected !== 1) {
	// 		let changedPostID = null;
	// 		let changedNewState = null;

	// 		const updatedPatients = newLayout
	// 			.map(layoutItem => {
	// 				const patient = sortedPatients.find(
	// 					patient => patient.postID.toString() === layoutItem.i,
	// 				);
	// 				if (patient) {
	// 					// 레이아웃 아이템의 위치 또는 크기가 변경되었는지 확인
	// 					if (
	// 						layoutItem.x !== patient.x ||
	// 						layoutItem.y !== patient.y ||
	// 						layoutItem.w !== patient.w ||
	// 						layoutItem.h !== patient.h
	// 					) {
	// 					}
	// 					if (patient.state !== allOptions[layoutItem.x]) {
	// 						changedPostID = layoutItem.i; // 위치 변경이 감지된 postID
	// 						changedNewState = allOptions[layoutItem.x]; // 변경된 새로운 상태
	// 						return { ...patient, state: changedNewState };
	// 					}
	// 				}
	// 				return patient;
	// 			})
	// 			.filter(patient => patient);

	// 		if (changedPostID && changedNewState && realData && realData.length > 0) {
	// 			// 드래그 앤 드롭에 의한 변경 시에만 실행
	// 			setSelectPostID(changedPostID);
	// 			setSelectState(changedNewState);
	// 		}
	// 		setRealdata(updatedPatients);
	// 		// setSortedPatients(updatedPatients);
	// 		onPatientDataChange(updatedPatients);
	// 	}

	// 	setLayout(newLayout);
	// };

	// 버튼 클릭시 업데이트 되는 LayoutChange
	const onLayoutChange = newLayout => {
		const initialStorageDentalLabs = JSON.parse(
			localStorage.getItem("initialStorageDentalLabs"),
		);
		if (patientDataNav.selected !== 1) {
			let updatesMap = new Map();

			newLayout.forEach(layoutItem => {
				const patient = sortedPatients.find(
					patient => patient.postID.toString() === layoutItem.i,
				);
				if (patient && patient.state !== allOptions[layoutItem.x]) {
					const newState = allOptions[layoutItem.x];
					const writer = initialStorageDentalLabs[2].writer.name; // writer 정보 가져오기
					// Map에 id를 키로 하여 newState와 writer를 값으로 저장합니다.
					// 동일한 id에 대해서는 마지막으로 처리된 newState와 writer로 업데이트됩니다.
					updatesMap.set(patient.postID, { newState, writer });
				}
			});

			// updatesMap에서 변경 사항을 배열로 변환합니다.
			const updates = Array.from(updatesMap).map(
				([id, { newState, writer }]) => ({
					id,
					newState,
					writer,
				}),
			);
			// console.log("updates", updates);
			// console.log("updatesMap", updatesMap);
			setChanges(prevChanges => [...updates]);
		}

		setLayout(newLayout);
	};

	// realData 및 dateRange가 변경될 때마다 실행될 useEffect
	useEffect(() => {
		// responsibleLab이 selectRlrhdth와 동일하고, receptionDate 및 deadline이 dateRange 내에 있는 데이터만 필터링
		const newFilteredData = realData.filter(patient => {
			const responsibleLabMatches = patient.responsibleLab === selectRlrhdth; // responsibleLab 조건
			const receptionDate = new Date(patient.receptionDate);
			const deadline = new Date(patient.deadline);
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);

			const dateRangeMatches =
				(receptionDate >= startDate && receptionDate <= endDate) ||
				(deadline >= startDate && deadline <= endDate);

			return responsibleLabMatches && dateRangeMatches; // 두 조건 모두 만족하는지 확인
		});

		setFilteredData(newFilteredData); // 필터링된 데이터를 상태에 저장
	}, [realData, dateRange, selectRlrhdth]); // 의존성 배열에 selectRlrhdth 추가

	useEffect(() => {
		setRealdata(sortedPatients);
	}, [sortedPatients]);

	const getStateColor = (state, bright) => {
		const processColors = [
			["#fbc04d", "#fff3d7", "#fdfaf3"],
			["#d45a52", "#ffe9e5", "#fdf6f5"],
			["#3da3b1", "#e1f4f6", "#f3fafb"],
			["#7b5c93", "#f8ebfe", "#fbf5fe"],
		];

		for (let i = 0; i < sampleData.DentalProcess.length; i++) {
			const process = sampleData.DentalProcess[i];
			if (process.options.includes(state)) {
				if (bright === "dark") {
					return processColors[i][0]; // 어두운 색상 반환
				} else if (bright === "middle") {
					return processColors[i][1]; // 중간 색상 반환
				} else if (bright === "bright") {
					return processColors[i][2]; // 밝은 색상 반환
				} else {
					return null; // 잘못된 bright 값이 들어온 경우 null 반환
				}
			}
		}
		return null; // 일치하는 option이 없을 경우 null 반환
	};

	return (
		<>
			{isLayoutReady ? (
				<div
					className={`patientDatas ${
						patientDataNav.formatClass[patientDataNav.selected]
					}Form`}
				>
					<GridLayout
						className={`patientDatas `}
						cols={patientDataNav.gridCols[patientDataNav.selected]}
						rowHeight={patientDataNav.gridRowHeight[patientDataNav.selected]}
						width={patientsDataBoxWidth}
						layout={layout}
						onLayoutChange={onLayoutChange}
						isDraggable={patientDataNav.selected === 0 && dndStatus}
						isResizable={patientDataNav.selected === 0 && true}
					>
						{filteredData &&
							filteredData.length > 0 &&
							filteredData.map(patient => (
								<div
									key={patient.postID}
									className="patientData"
									onDoubleClick={() => handleItemClick(patient.postID)} // 클릭 이벤트 핸들러 추가
									style={{
										display: hiddenOptions.includes(patient.state)
											? "none"
											: patientDataNav.selected === 0
												? "block"
												: "flex",
										backgroundColor:
											patientDataNav.selected === 1 &&
											getStateColor(patient.state, "bright"),
									}}
								>
									{patientDataNav.selected === 0 ? (
										<>
											{/* patient 데이터를 사용한 UI 구성 */}
											{
												// patient.isEdited ||
												patient.isNotified && <div className="redPoint"></div>
											}

											<p className="patientName">{patient.patientName}</p>
											<p className="chartNumber"># {patient.chartNumber}</p>
											<p>
												기간 : {patient.receptionDate.split("T")[0]} ~{" "}
												{patient.deadline.split(" ")[0]}
											</p>
											<p>의사: {patient.doctorName}</p>
											<p>작성자: {patient.writer}</p>
											<p>담당기공소: {patient.responsibleLab}</p>
											<p>
												기공물 종류:{" "}
												{Rlrhdanfwhdfb.find(
													type => type.id === Number(patient.prosthesisType),
												)?.label || ""}
											</p>
											<p>
												보철물 종류:{" "}
												{patient.restorationType &&
													getProsthesisTypeNames(
														JSON.parse(patient.restorationType),
													)}
											</p>

											<p>
												첨부파일:{" "}
												{patient.attachmentUrl.length > 0
													? `${patient.attachmentUrl.length}개`
													: "없음"}
											</p>
											<p>쉐이드: {JSON.parse(patient.shade).basic}</p>
											<p>- 절단면 색상: {JSON.parse(patient.shade).first}</p>
											<p>- 중간 색상: {JSON.parse(patient.shade).second}</p>
											<p>- 치간부 색상: {JSON.parse(patient.shade).third}</p>
											<p>ponicType: {patient.ponticType}</p>
											<p>trayType: {patient.trayType}</p>
											<p>치식: {patient.cltlr.replace(/[\[\]]/g, "")}</p>
											<p
												className="patientState"
												style={
													{
														// backgroundColor:
														// 	patientDataNav.selected === 1
														// 		? `rgb(${
														// 				130 +
														// 				((allOptions.indexOf(patient.state) * 20) % 180)
														// 			}, ${
														// 				130 +
														// 				((allOptions.indexOf(patient.state) * 20) % 180)
														// 			}, ${
														// 				130 +
														// 				((allOptions.indexOf(patient.state) * 20) % 180)
														// 			})`
														// 		: "",
														// color: "#333",
													}
												}
											>
												{patient.state}
											</p>
										</>
									) : (
										<>
											<p className="patientState">
												<span
													style={{
														backgroundColor: getStateColor(
															patient.state,
															"dark",
														),
													}}
												>
													{patient.state}
												</span>
											</p>

											<p className="patientName">{patient.patientName}</p>
											<p className="chartNumber"># {patient.chartNumber}</p>
											<p className="responsibleLab">{patient.responsibleLab}</p>
											<p className="receptionDate">
												{patient.receptionDate.split(" ")[0]}
											</p>
											<p className="deadline">
												{patient.deadline.split(" ")[0]}
											</p>
											<p className="doctorName">{patient.doctorName}</p>
											<p className="writer">{patient.writer}</p>
										</>
									)}
								</div>
							))}
					</GridLayout>
				</div>
			) : (
				<div>Loading...</div> // GridLayout 렌더링 전에 보여질 내용
			)}
		</>
	);
};

export default Grid;
