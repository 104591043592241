import React, { useState, useEffect } from "react";

const WriterModal = ({
	setSelectedWriter,
	uniqueWriters,
	writerModalHandle,
}) => {
	const [writers, setWriters] = useState([]);

	useEffect(() => {
		// 로컬스토리지에서 삭제된 작성자 ID 목록 가져오기
		const initialStorageDentalLabs =
			JSON.parse(localStorage.getItem("initialStorageDentalLabs")) || [];
		const deletedWriters = initialStorageDentalLabs[3]?.deleted || [];

		// uniqueWriters가 업데이트될 때 상태를 설정하고, 삭제된 작성자 필터링
		setWriters(
			uniqueWriters
				.map((name, index) => ({ id: index + 1, name }))
				.filter(writer => !deletedWriters.includes(writer.id)),
		);
	}, [uniqueWriters]);

	const handleWriterClick = writer => {
		setSelectedWriter(writer);
		writerModalHandle();
	};

	return (
		<div className="writerModal">
			<div>
				<ul>
					{writers.map(writer => (
						<li key={writer.id} onClick={() => handleWriterClick(writer)}>
							{writer.name}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default WriterModal;
