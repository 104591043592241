import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormBTN } from "../../components/Button";

const Login = () => {
	const navigate = useNavigate();
	const [loginInfo, setLoginInfo] = useState({
		userName: "",
		userPw: "",
	});

	// 입력 핸들링을 위한 함수

	const handleInputChange = e => {
		const { name, value } = e.target;
		setLoginInfo(prevInfo => ({
			...prevInfo,
			[name]: value,
		}));
	};
	// 폼 제출 핸들러
	const handleSubmit = async e => {
		e.preventDefault();

		if (loginInfo.userName.length < 2) {
			alert("아이디를 정확히 입력해주세요.");
			return; // 여기서 함수 실행을 중단
		}
		// 로그인 정보를 서버에 전송
		try {
			const response = await axios.post("/api/main/users/login", {
				email: loginInfo.userName,
				password: loginInfo.userPw,
			});

			// 로그인 성공: 서버로부터 받은 토큰을 로컬 스토리지에 저장
			localStorage.setItem("accessToken", response.data.accessToken);
			localStorage.setItem("refreshToken", response.data.refreshToken);

			// 사용자 대시보드나 홈페이지로 리디렉션
			navigate("/home"); // 대시보드 경로에 맞게 조정해야 합니다.
		} catch (error) {
			// 로그인 실패: 오류 메시지 표시
			console.error("로그인 실패:", error);
			alert("로그인에 실패했습니다.");
		}
	};
	const clearlocal = () => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("initialStorageDentalLabs");
		localStorage.removeItem("dateRange");
		localStorage.removeItem("selectedOption");
	};
	return (
		<div className="loginContainer contents">
			<h2>
				치과의 모든 것, <span>덴토탈</span>
			</h2>
			<form className="loginForm" onSubmit={handleSubmit}>
				<div>
					<div className="InputBtw">
						<label htmlFor="userEmail">
							이메일<span>*</span>
						</label>
						<Link to="/login/idfind">아이디 찾기</Link>
					</div>
					<input
						id="userEmail"
						type="text"
						name="userName"
						placeholder="이메일을 입력해주세요."
						value={loginInfo.userName}
						onChange={handleInputChange}
					/>
				</div>
				<div>
					<div className="InputBtw">
						<label htmlFor="userPw">
							비밀번호<span>*</span>
						</label>
						<Link to="/login/passfind">비밀번호 찾기</Link>
					</div>
					<input
						id="userPw"
						type="password"
						name="userPw"
						placeholder="비밀번호를 입력해주세요"
						value={loginInfo.userPw}
						onChange={handleInputChange}
					/>
				</div>
				<div className="alignItem">
					<input id="keepingLogin" className="keepingLogin" type="checkbox" />
					<label className="keepingLoginLabel" htmlFor="keepingLogin">
						로그인 상태 유지
					</label>{" "}
				</div>
				<div>
					<FormBTN type="submit" detail="로그인" onClick={handleSubmit}>
						가입하기
					</FormBTN>
				</div>
				<div className="flexRow">
					<p>덴토탈 계정이 없으신가요? </p>
					<Link to="/join">무료 회원가입</Link>
				</div>
			</form>
			<button onClick={clearlocal}>오류 해결 임시 버튼</button>
		</div>
	);
};

export default Login;
