import React, { useState, useEffect, useRef } from "react";

const PasteEventListener = ({
	drawingBoardImageFile,
	setDrawingBoardImageFile,
}) => {
	const pastAreaRef = useRef(null);

	useEffect(() => {
		const handlePaste = event => {
			const clipboardData = event.clipboardData || window.clipboardData;
			const items = Array.from(clipboardData.items);

			items.forEach(item => {
				if (item.kind === "file") {
					const file = item.getAsFile();

					if (file.type.startsWith("image/")) {
						setDrawingBoardImageFile(prevContent => [
							...prevContent,
							{ type: "image", content: file, id: Date.now() + Math.random() },
						]);
					} else if (file.type.startsWith("video/")) {
						setDrawingBoardImageFile(prevContent => [
							...prevContent,
							{ type: "video", content: file, id: Date.now() + Math.random() },
						]);
					} else {
						const reader = new FileReader();
						reader.onload = e => {
							setDrawingBoardImageFile(prevContent => [
								...prevContent,
								{
									type: "file",
									content: file,
									id: Date.now() + Math.random(),
								},
							]);
						};
						reader.readAsDataURL(file);
					}
				}
			});

			event.preventDefault();
		};

		const pastArea = pastAreaRef.current;
		pastArea.addEventListener("paste", handlePaste);

		return () => {
			pastArea.removeEventListener("paste", handlePaste);
		};
	}, []);

	const handleDelete = id => {
		setDrawingBoardImageFile(prevContent =>
			prevContent.filter(content => content.id !== id),
		);
	};

	const renderContent = () => {
		return drawingBoardImageFile.map(content => (
			<div key={content.id}>
				{content.type === "image" && (
					<img
						src={
							content.url ? content.url : URL.createObjectURL(content.content)
						}
						alt="Pasted"
					/>
				)}
				{content.type === "video" && (
					<video
						src={
							content.url ? content.url : URL.createObjectURL(content.content)
						}
						controls
					/>
				)}
				<button onClick={() => handleDelete(content.id)}>X</button>
			</div>
		));
	};

	// console.log(drawingBoardImageFile);
	return (
		<div className="past">
			<input
				ref={pastAreaRef}
				placeholder={
					drawingBoardImageFile.length === 0 ? "여기에 붙여넣기" : ""
				}
				readOnly
			/>
			<div className="output">{renderContent()}</div>
		</div>
	);
};

export default PasteEventListener;
