import React from "react";
import { updates } from "./updateData";

const UpdateModal = ({ setUpdatedModal }) => {
	const closeUpdateModal = () => {
		setUpdatedModal(false);
	};

	return (
		<div className="modalBackground">
			<div className="modalContainer">
				<div className="modalHeader">
					<h2>프로그램 업데이트 내역</h2>
				</div>
				<div className="modalContent">
					<ul>
						{updates
							.slice()
							.reverse()
							.map((update, index) => (
								<li
									key={index}
									style={{
										color: update.color === 2 ? "blue" : "black",
									}}
								>
									<strong>{update.date}:</strong>
									{Array.isArray(update.description) ? (
										<ul>
											{update.description.map((desc, descIndex) => (
												<li key={descIndex}>{desc}</li>
											))}
										</ul>
									) : (
										<span> {update.description}</span>
									)}
								</li>
							))}
					</ul>
				</div>
				<div className="modalFooter">
					<button onClick={closeUpdateModal}>닫기</button>
				</div>
			</div>
		</div>
	);
};

export default UpdateModal;
