import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userState } from "../../states/atoms/JoinStates";
import { FormBTN } from "../../components/Button";
import { JoinSelect } from "../../components/SelectBox";
import { JoinInput } from "../../components/InputBox";
import { FindAddress } from "../../components/FindAddress.jsx";
import JoinData from "../../utils/data.jsx";

export function JoinCompanyInfo() {
	const [companyInfo, setCompanyInfo] = useState({
		hospitalName: "",
		ceoName: "",
		businessNumber: "",
		funnel: "",
		address: "",
		detailAddress: "",
	});

	const HandleInputChange = e => {
		const { name, value } = e.target;
		setCompanyInfo(prev => ({
			...prev,
			[name]: value,
		}));
	};

	const HandleSubmit = e => {
		console.log(companyInfo);
	};

	return (
		<div className="joinCompanyInfo contents">
			<h3>필요한 정보가 있어요</h3>
			<h4>병원 인사관리에 필요한 추가 정보를 입력해주세요.</h4>

			<JoinInput
				labelfor="병원명"
				span="*"
				placeholder="병원명을 입력하세요"
				value={companyInfo.hospitalName} // 수정된 부분
				name="hospitalName"
				onChange={HandleInputChange}
			/>
			<JoinInput
				labelfor="담당자 이름"
				placeholder="담당자 이름을 입력해주세요."
				value={companyInfo.ceoName}
				name="ceoName"
				onChange={HandleInputChange}
			/>
			<JoinInput
				labelfor="사업자 번호"
				span="*"
				placeholder="사업자 번호를 입력해주세요."
				value={companyInfo.businessNumber}
				name="businessNumber"
				onChange={HandleInputChange}
				type="number"
			/>

			<FindAddress
				labelfor="주소"
				span="*"
				name="address"
				address={companyInfo.address}
				detailAddress={companyInfo.detailAddress}
				onChange={HandleInputChange}
			/>

			<JoinSelect
				className="funnels"
				labelfor="유입경로"
				placeholder="유입경로를 선택해주세요."
				name="funnel"
				data={JoinData.JoinSelectData}
				onChange={HandleInputChange}
			/>

			<div className="userGuide">
				<h5>
					<img
						src={`${process.env.PUBLIC_URL}/images/login/Info_circle.png`}
						alt="이용안내"
					/>
					이용안내
				</h5>
				<ul>
					<li>- 최초 가입자가 소유자로 등록됨니다.</li>
					<li>- 가입 및 서비스 등록 후 추가 서류나 정보 입력이 필요합니다.</li>
					<li>
						- 소유자 외 사용자는 소유자 등록 후 초대 기능을 이용해 주세요.
					</li>
					<li>- 제휴가 필요하다면 고객센터로 문의주세요.</li>
				</ul>
			</div>

			<FormBTN type="submit" detail="시작하기" onClick={HandleSubmit}></FormBTN>
		</div>
	);
}
