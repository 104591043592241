import React, { useEffect, useRef, useState } from "react";

export default function Canvas({ drawingBoard, onChange }) {
	const canvasRef = useRef(null);
	const [painting, setPainting] = useState(false);
	const [selectedBackground, setSelectedBackground] = useState("none"); // 현재 선택된 그림을 나타내는 상태 변수
	const [lines, setLines] = useState(drawingBoard); // 사용자가 그린 선들을 저장하는 객체
	const [removedLines, setRemovedLines] = useState({}); // 제거된 선들을 저장할 상태
	const [mode, setMode] = useState("pen");
	const backgroundOption = [
		{ key: "none", label: "배경없음" },
		{ key: "occlusal", label: "상하악교합면" },
		{ key: "frontal", label: "정면" },
		{ key: "buccal", label: "구치부설측" },
		{ key: "buccalSide", label: "구치부단면" },
		{ key: "lingualSide", label: "구치부순측" },
	];

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		canvas.width = 750;
		canvas.height = 500;
		ctx.lineJoin = "round";
		ctx.lineWidth = 1.5;
		ctx.strokeStyle = "#000000";
		changeBackground(selectedBackground);
	}, []);

	useEffect(() => {
		setLines(drawingBoard);
		drawLines();
	}, [drawingBoard]);

	useEffect(() => {
		changeBackground();
		drawLines();
	}, [selectedBackground, removedLines, mode]);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (mode === "eraser") {
			canvas.style.cursor = "url(/images/canvas/eraserBtn.png), auto";
		} else if (mode === "pen") {
			canvas.style.cursor = "url(/images/canvas/penBtn.png)-30 32, auto";
		} else {
			canvas.style.cursor = "default";
		}
	}, [mode]);
	// console.log(mode);
	const preventScroll = e => {
		e.preventDefault(); // 스크롤 이벤트의 기본 동작 막기
	};

	const handleMouseDown = e => {
		// 그림 그리기 상태 활성화
		setPainting(true);

		// 마우스 클릭한 위치의 x, y 좌표
		const mouseX = e.nativeEvent.offsetX;
		const mouseY = e.nativeEvent.offsetY;

		if (mode === "pen") {
			// 선택된 배경에 해당하는 점 배열에 새로운 점 추가
			setLines(prevLines => ({
				...prevLines,
				[selectedBackground]: [
					...(prevLines[selectedBackground] || []), // 해당 배경의 선 배열이 없으면 새 배열로 시작
					[[mouseX, mouseY]],
				],
			}));
		}
	};

	const handleTouchStart = e => {
		// 스크롤 방지 활성화
		window.addEventListener("touchmove", preventScroll, { passive: false });

		const touch = e.touches[0];
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		ctx.beginPath(); // 새로운 그리기 경로를 시작합니다.

		const rect = canvas.getBoundingClientRect();
		const touchX = touch.clientX - rect.left;
		const touchY = touch.clientY - rect.top;

		ctx.moveTo(touchX, touchY); // 이동하지 않고 시작점을 설정합니다.

		// 그림 그리기 상태 활성화
		setPainting(true);

		// 선택된 배경에 해당하는 점 배열에 새로운 점 추가
		setLines(prevLines => ({
			...prevLines,
			[selectedBackground]: [
				...(prevLines[selectedBackground] || []), // 해당 배경의 선 배열이 없으면 새 배열로 시작
				[[touchX, touchY]],
			],
		}));
	};

	const handleEraserMouseMove = (mouseX, mouseY) => {
		//const mouseX = e.nativeEvent.offsetX;
		//const mouseY = e.nativeEvent.offsetY;
		const ctx = canvasRef.current.getContext("2d");

		// 지우개 모드일 때 커서에 닿은 선을 removedLines로 이동
		setLines(prevLines => {
			const currentLines = [...prevLines[selectedBackground]];
			if (currentLines.length === 0) return prevLines; // 선이 없다면 아무것도 하지 않음

			let removedLine = null;

			// 커서에 닿은 선 찾기
			for (let i = 0; i < currentLines.length; i++) {
				const line = currentLines[i];
				for (let j = 0; j < line.length; j++) {
					const [x, y] = line[j];
					const dx = x - mouseX;
					const dy = y - mouseY;
					const distance = Math.sqrt(dx * dx + dy * dy);

					if (distance < 30) {
						// 커서와 선의 거리가 10픽셀 이내이면 해당 선을 제거
						removedLine = currentLines.splice(i, 1)[0];
						break;
					}
				}
				if (removedLine) break;
			}

			if (removedLine) {
				// 제거된 선을 removedLines에 저장
				setRemovedLines(prevRemoved => ({
					...prevRemoved,
					[selectedBackground]: [
						...(prevRemoved[selectedBackground] || []),
						removedLine,
					],
				}));
			}

			return {
				...prevLines,
				[selectedBackground]: currentLines,
			};
		});
	};

	const handleMouseMove = e => {
		const mouseX = e.nativeEvent.offsetX;
		const mouseY = e.nativeEvent.offsetY;
		const ctx = canvasRef.current.getContext("2d");

		if (mode === "eraser" && painting) {
			handleEraserMouseMove(mouseX, mouseY);
		} else if (mode === "pen" && painting) {
			// 그림 그리기 상태인 경우

			ctx.lineTo(mouseX, mouseY);
			ctx.stroke();

			//handleMouseDown으로 가장 최근에 추가된 점을 업데이트
			const updatedLines = { ...lines };
			const lastIndex = updatedLines[selectedBackground].length - 1;
			// movePoints에 현재의 마우스 이동 경로 추가
			updatedLines[selectedBackground][lastIndex].push([mouseX, mouseY]);

			// 업데이트된 lines 배열을 설정합니다.
			setLines(updatedLines);
		} else {
			// 그림 그리기 상태가 아닌 경우 새로운 경로를 시작
			ctx.beginPath();
			ctx.moveTo(mouseX, mouseY);
		}
	};

	const handleTouchMove = e => {
		const touch = e.touches[0];
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d"); // 컨텍스트를 정확하게 가져옵니다.
		const rect = canvas.getBoundingClientRect();
		const touchX = touch.clientX - rect.left;
		const touchY = touch.clientY - rect.top;

		// 그림 그리기 상태인 경우
		if (painting) {
			ctx.lineTo(touchX, touchY);
			ctx.stroke();

			//handleMouseDown으로 가장 최근에 추가된 점을 업데이트
			const updatedLines = { ...lines };
			const lastIndex = updatedLines[selectedBackground].length - 1;
			// movePoints에 현재의 마우스 이동 경로 추가
			updatedLines[selectedBackground][lastIndex].push([touchX, touchY]);

			// 업데이트된 lines 배열을 설정합니다.
			setLines(updatedLines);
		} else {
			// 그림 그리기 상태가 아닌 경우 새로운 경로를 시작
			ctx.beginPath();
			ctx.moveTo(touchX, touchY);
		}

		// 스크롤 방지 활성화
		window.addEventListener("touchmove", preventScroll, { passive: false });
	};

	const handleTouchEnd = () => {
		setPainting(false);
		window.addEventListener("touchmove", preventScroll, { passive: true });
		const ctx = canvasRef.current.getContext("2d");
		ctx.beginPath();
	};

	const changeBackground = () => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		const img = new Image();

		// 캔버스의 현재 내용을 클리어.
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		if (selectedBackground === "none") {
			// 배경 없음을 클릭했을 때 캔버스 배경 흰색.
			ctx.fillStyle = "#ffffff"; // 흰색 배경
			ctx.fillRect(0, 0, canvas.width, canvas.height);
		} else {
			// 그 외의 경우 이미지.
			img.onload = () => {
				const imgAspectRatio = img.width / img.height; // 이미지의 가로세로 비율을 계산합
				const canvasAspectRatio = canvas.width / canvas.height; // 캔버스의 가로세로 비율을 계산

				let newWidth, newHeight;
				if (imgAspectRatio > canvasAspectRatio) {
					// 이미지가 가로로 더 긴 경우
					newWidth = canvas.width * 0.65; // 이미지의 너비를 캔버스의 너비의 80%에 맞춤
					newHeight = newWidth / imgAspectRatio;
				} else {
					// 이미지가 세로로 더 긴 경우
					newHeight = canvas.height * 0.65; // 이미지의 높이를 캔버스의 높이의 80%에 맞춤
					newWidth = newHeight * imgAspectRatio; // 이미지의 너비를 세로 비율에 따라 조정
				}

				const x = (canvas.width - newWidth) / 2; // 이미지를 보여줄 x 좌표를 계산
				const y = (canvas.height - newHeight) / 2; // 이미지를 보여줄 y 좌표를 계산

				ctx.drawImage(img, x, y, newWidth, newHeight); // 이미지를 캔버스에 보여주기
			};
		}

		img.src = `${process.env.PUBLIC_URL}/images/canvas/${selectedBackground}.png`;
	};

	const drawLines = () => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");

		if (lines[selectedBackground]) {
			// 선택된 배경 이미지에 해당하는 선 보여주기.
			lines[selectedBackground].forEach(line => {
				ctx.beginPath();
				ctx.moveTo(line[0][0], line[0][1]);
				line.forEach(point => {
					ctx.lineTo(point[0], point[1]);
				});

				ctx.stroke();
			});
		}
	};

	const moveBackward = () => {
		setLines(prevLines => {
			const currentLines = [...prevLines[selectedBackground]];
			if (currentLines.length === 0) return prevLines; // 선이 없다면 아무것도 하지 않음

			const removedLine = currentLines.pop(); // 마지막 선을 제거

			// 제거된 선을 removedLines에 저장
			setRemovedLines(prevRemoved => ({
				...prevRemoved,
				[selectedBackground]: [
					...(prevRemoved[selectedBackground] || []),
					removedLine,
				],
			}));

			return {
				...prevLines,
				[selectedBackground]: currentLines,
			};
		});
	};

	const moveForward = () => {
		setRemovedLines(prevRemoved => {
			const currentRemoved = [...(prevRemoved[selectedBackground] || [])];
			if (currentRemoved.length === 0) return prevRemoved; // 제거된 선이 없다면 아무것도 하지 않음

			const restoredLine = currentRemoved.pop(); // 가장 최근에 제거된 선을 꺼냄

			// 꺼낸 선을 lines에 다시 추가
			setLines(prevLines => ({
				...prevLines,
				[selectedBackground]: [...prevLines[selectedBackground], restoredLine],
			}));

			return {
				...prevRemoved,
				[selectedBackground]: currentRemoved,
			};
		});
	};

	// 저장 버튼 클릭 시 호출되는 함수
	const saveDrawing = () => {
		onChange(lines);
		alert("그림이 저장되었습니다. 의뢰서까지 저장해주세요.");
	};

	return (
		<div>
			<div className="flexRow movesaveBtn">
				<button onClick={saveDrawing}>
					<img
						src={`${process.env.PUBLIC_URL}/images/canvas/saveBtn.png`}
						alt="저장"
					/>
				</button>
				<button onClick={moveBackward}>
					<img
						src={`${process.env.PUBLIC_URL}/images/canvas/backwardBtn.png`}
						alt="뒤로가기"
					/>
				</button>
				<button onClick={moveForward}>
					<img
						src={`${process.env.PUBLIC_URL}/images/canvas/forwardBtn.png`}
						alt="앞으로가기"
					/>
				</button>
				<button onClick={() => setMode("pen")}>
					<img
						src={`${process.env.PUBLIC_URL}/images/canvas/penBtn.png`}
						alt="펜"
					/>
				</button>
				<button onClick={() => setMode("eraser")}>
					<img
						src={`${process.env.PUBLIC_URL}/images/canvas/eraserBtn.png`}
						alt="지우개"
					/>
				</button>
			</div>
			<canvas
				className="imgFileBox"
				ref={canvasRef}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={() => {
					setPainting(false);
				}}
				onMouseLeave={() => {
					setPainting(false);
				}}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			></canvas>
			<div className="flexRow backgroundBtn">
				{backgroundOption.map(({ key, label }) => (
					<button
						key={key}
						className={selectedBackground === key ? "colorBtn" : ""}
						onClick={() => {
							setSelectedBackground(key);
						}}
					>
						{key != "none" && (
							<img
								src={`${process.env.PUBLIC_URL}/images/canvas/${key}.png`}
								alt={label}
							/>
						)}
					</button>
				))}
			</div>
		</div>
	);
}
