export const PostTitleInputData = [
	{
		id: "responsible-doctor",
		label: "담당의사",
	},
	{
		id: "author",
		label: "작성자",
	},
];

export const PostHeaderInputData = [
	{
		id: "patientName",
		label: "환자이름",
	},
	{
		id: "chartNumber",
		label: "차트번호",
	},
	{
		id: "responsibleLab",
		label: "담당기공소",
	},
	{
		id: "receptionDate",
		label: "접수일",
	},
	{
		id: "deadline",
		label: "마감일",
	},
];

export const Rlrhdanfwhdfb = [
	{ id: 1, label: "크라운/브릿지" },
	{ id: 2, label: "인레어" },
	{ id: 3, label: "임플란트" },
	{ id: 4, label: "틀니" },
	{ id: 5, label: "교정장치" },
	{ id: 6, label: "기타" },
];

export const PonticType = [
	{ id: "SaddleType", label: "Saddle Type" },
	{ id: "FullRidge", label: "Full Ridge" },
	{ id: "PartialRidge", label: "Partial Ridge" },
	{ id: "Ovate", label: "Ovate" },
	{ id: "Sanitary", label: "Sanitary" },
];

export const xmfpdl = [
	{ id: "Full", label: "Full" },
	{ id: "Partial", label: "Partial" },
];

export const Qhcjfanfwhdfb = [
	{ id: "1", label: "골드 Cr(PT)" },
	{ id: "2", label: "골드 Cr(Super)" },
	{ id: "3", label: "골드 Cr(A Ttype)" },
	{ id: "4", label: "골드 Cr(A Ttype)" },
	{ id: "5", label: "메탈 Cr" },
	{ id: "6", label: "엠프레스 Cr" },
	{ id: "7", label: "지르코니아 Cr" },
	{ id: "8", label: "PFM Cr" },
	{ id: "9", label: "PFM 캡 제작" },
	{ id: "10", label: "PFM Porcelain Build-up" },
	{ id: "11", label: "PFG Cr" },
	{ id: "12", label: "PFG 캡 제작" },
];

export const Teeth = {
	upper: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
	upperDeciduous: [55, 54, 53, 52, 51, 61, 62, 63, 64, 65],
	lowerDeciduous: [85, 84, 83, 82, 81, 71, 72, 73, 74, 75],
	lower: [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
};
