import React, { useState, useEffect } from "react";
import sampleData from "../../data/sampleData.js";
/* 전체화면 */
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Grid from "./Grid.jsx"; // Grid 컴포넌트 추가
import Calendar from "./Calendar.jsx"; // Calendar 컴포넌트 추가
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";

export function PatientsDatas(props) {
	// 네비 로직
	const navigate = useNavigate();

	const qhcjfanfwhdfb = props.qhcjfanfData;
	const handleNavigate = () => {
		// 알림창을 표시
		const confirm = window.confirm("의뢰서를 작성하시겠습니까?");
		// 사용자가 '예'를 선택한 경우
		if (confirm) {
			navigate("/post");
		}
	};
	const toggleDndStatus = () => {
		props.setDndStatus(!props.dndStatus);
		if (props.dndStatus) {
			// window.location.reload(); // 페이지 새로고침
			// console.log(props.selectedOption);
		}
		// console.log(props.dndStatus);
	};
	const [patientsDataBoxWidth, setPatientsDataBoxWidth] = useState(0); //GridLayOut %로 셋팅
	const [patientsDataBoxHeight, setPatientsDataBoxHeight] = useState(0);
	const [allOptions, setAllOptions] = useState([]); //기공상태 나열
	const [sort, setSort] = useState({
		key: "deadline",
		order: "asc",
	}); //정렬기준

	const [sortedPatients, setSortedPatients] = useState([]); //환자 정렬
	const [patientDataNav, setPatientDataNav] = useState({
		selected: 0,
		formats: ["보드", "표", "캘린더"],
		formatClass: ["bord", "table", "calendar"],
		gridCols: [allOptions.length, 1, 100],
		gridRowHeight: [50, 30, ""],
	});
	const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태
	const [layout, setLayout] = useState([]); //환자 GridLayOut 형식에 맞추기(x, y 등 추가)

	/* 전체화면 */
	const fullScreenHandle = useFullScreenHandle();
	// console.log(fullScreenHandle.active);

	//.patientsDataBox 너비,높이 설정
	useEffect(() => {
		const handleResize = () => {
			const boxElement = document.querySelector(".patientsDataBox");
			const homeNavSectionElement = document.querySelector(".homeNavSection");
			const homeSidebarSectionElement = document.querySelector(
				".homeSidebarSection",
			);
			const dentalCurrentStateElement = document.querySelector(
				".dentalCurrentState",
			);

			if (props.selectedOption[0] !== "통합") {
				const boxStyles = window.getComputedStyle(boxElement);
				//const boxWidth =
				// boxElement.clientWidth -
				// 	parseFloat(boxStyles.paddingLeft) -
				// 	parseFloat(boxStyles.paddingRight);
				let boxWidth;
				let boxHeight;
				if (props.sidebarOpen && !fullScreenHandle.active) {
					boxWidth =
						window.innerWidth - homeSidebarSectionElement.offsetWidth - 100;
				} else {
					boxWidth = window.innerWidth - 10;
				}
				const homeNavSectionHeight = homeNavSectionElement.offsetHeight;
				const dentalCurrentStateHeight = dentalCurrentStateElement.offsetHeight;

				boxHeight =
					window.innerHeight -
					homeNavSectionHeight -
					dentalCurrentStateHeight -
					150;

				setPatientsDataBoxWidth(boxWidth);
				setPatientsDataBoxHeight(boxHeight);
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		// console.log("patientsDataBoxWidth", patientsDataBoxWidth);
		// 의존성 배열에 props.selectedOption 추가
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [props.selectedOption, props.sidebarOpen, fullScreenHandle.active]);
	// console.log("patientsDataBoxWidth", patientsDataBoxWidth);
	// 날짜를 받아 "YYYY-MM-DD" 형식으로 포맷팅하는 함수
	const formatDate = date => {
		// 날짜를 두 자리수로 표시하는 함수
		const padZero = num => (num < 10 ? `0${num}` : `${num}`);
		const year = date.getFullYear();
		const month = padZero(date.getMonth() + 1);
		const day = padZero(date.getDate());
		return `${year}-${month}-${day}`;
	};

	// 기공상태 나열
	useEffect(() => {
		// const updatedAllOptions = props.dentalProcess.flatMap(
		// 	process => process.options,
		// );
		// setAllOptions(updatedAllOptions);
		// 로컬 스토리지에서 가져온 데이터를 제외한 기공상태를 나열
		const updatedAllOptions = props.dentalProcess.flatMap(
			process => process.options,
		);
		const filteredOptions = updatedAllOptions.filter(
			option => !props.hiddenOptions.includes(option),
		);
		setAllOptions(filteredOptions);
	}, [props.selectedOption, props.dentalProcess, props.hiddenOptions]);

	// console.log(allOptions);
	// 검색어 입력 핸들러
	const handleSearchChange = event => {
		setSearchTerm(event.target.value);
	};
	const handleSearchNone = () => {
		setSearchTerm("");
	};
	useEffect(() => {
		if (props.homeData) {
			const results = props.homeData.filter(
				patient =>
					(patient.patientName
						.toLowerCase()
						.includes(searchTerm.toLowerCase()) ||
						patient.chartNumber
							.toLowerCase()
							.includes(searchTerm.toLowerCase())) &&
					!props.hiddenOptions.includes(patient.state),
			);

			props.setFilterdata(results);
		}
	}, [searchTerm, props.homeData, props.hiddenOptions]);

	useEffect(() => {
		const handleSort = debounce(() => {
			// 정렬 로직
			const sorted = props.filterdata.sort((a, b) => {
				const dateA = new Date(a[sort.key]);
				const dateB = new Date(b[sort.key]);
				return sort.order === "asc" ? dateA - dateB : dateB - dateA;
			});

			// 새로운 레이아웃 생성 및 상태 업데이트
			const newLayout = createLayout(sorted, allOptions);
			setSortedPatients(sorted);
			setLayout(newLayout);
		}, 500); // 500ms 디바운싱

		handleSort();

		// 컴포넌트 언마운트 시 디바운스 함수 취소
		return () => handleSort.cancel();
	}, [props.filterdata, sortedPatients, sort.key, sort.order, patientDataNav]);

	const handleSortChange = (key = sort.key, order = sort.order) => {
		setSortedPatients(prevSortedPatients => {
			const sorted = [...prevSortedPatients].sort((a, b) => {
				return order === "asc"
					? a[key].localeCompare(b[key], undefined, { numeric: true })
					: b[key].localeCompare(a[key], undefined, { numeric: true });
			});

			const newLayout = createLayout(sorted, allOptions);
			setLayout(newLayout);
			return sorted;
		});

		setSort({ key, order });
	};

	//정렬기준에 따라 정렬한 환자를 GridLayout 형식에 맞게 값 주기
	const createLayout = (sortedPatients, allOptions) => {
		// console.log("create Layout Console", props.dndStatus);
		return sortedPatients.map((patient, index) => {
			// "보드" 뷰에서는 상태에 따라 x 값을 다르게 설정
			let xx =
				patientDataNav.selected === 1 ? 0 : allOptions.indexOf(patient.state);

			// hiddenOptions의 변화에 따라 x 값 조정
			//xx -= props.hiddenOptions.length;

			// x 값이 allOptions 배열의 길이를 넘어가면 0으로 설정하여 버그를 방지합니다.
			// 또한, 음수인 경우에도 0으로 설정하여 음수 값이 되는 것을 방지합니다.
			//xx = Math.max(0, Math.min(xx, allOptions.length - 1));

			return {
				i: patient.postID.toString(),
				x: xx, // 수정된 로직을 사용하여 x 값을 설정
				y: index,
				w: 1,
				h: 1,
				maxW: 1,
				maxH: 8,
			};
		});
	};

	// console.log(props.selectedOption);
	// 보드, 표, 캘린더 네비 핸들러
	const handleLiClick = index => {
		setPatientDataNav(prevState => ({ ...prevState, selected: index }));
	};

	// Drag and Drop 기능을 토글하는 함수
	// 로컬스토리지에서 데이터 불러오기
	const initialStorageDentalLabs = JSON.parse(
		localStorage.getItem("initialStorageDentalLabs"),
	);
	return (
		<div
			className={`borderBox patientsDataBoxWrap`}
			style={
				{
					//height: `${patientsDataBoxHeight}px`,
				}
			}
		>
			<FullScreen
				className={`patientsDataBox ${
					fullScreenHandle.active ? "fullScreen" : ""
				}`}
				handle={fullScreenHandle}
			>
				<div className="nav">
					{props.selectedOption[0] !== "통합" && (
						<ul>
							{patientDataNav.formats.map((item, index) => (
								<li
									key={index}
									className={
										patientDataNav.selected === index ? "navSelect" : ""
									}
									onClick={() => handleLiClick(index)}
								>
									{item}
								</li>
							))}
						</ul>
					)}
					{fullScreenHandle.active ? (
						<button
							className="fullScreenHandle"
							onClick={() => {
								fullScreenHandle.exit();
							}}
						>
							<img
								src={`${process.env.PUBLIC_URL}/images/patientDatas/Full_Screen_Corner.png`}
								alt="전체화면 해제"
							/>
							전체화면 해제
						</button>
					) : (
						<button
							className="fullScreenHandle"
							onClick={() => {
								fullScreenHandle.enter();
							}}
						>
							<img
								src={`${process.env.PUBLIC_URL}/images/patientDatas/Full_Screen_Corner.png`}
								alt="전체화면 보기"
							/>
							전체화면 보기
						</button>
					)}
					<div>
						<div className="dataSearch">
							{/* <button onClick={handleSearchChange}> */}
							<img
								src={`${process.env.PUBLIC_URL}/images/patientDatas/Search.png`}
								alt="차트번호 또는 환자이름 검색"
							/>
							{/* </button> */}
							<input
								type="text"
								placeholder="차트번호 또는 환자이름 검색"
								value={searchTerm}
								onChange={handleSearchChange} // 검색어 입력 핸들러 추가
							/>
							<button onClick={handleSearchNone}>X</button>
						</div>
						<div className="sortBtn">
							{["receptionDate", "deadline"].map(sortKey => (
								<button
									key={sortKey}
									onClick={() => handleSortChange(sortKey)}
									className={`sortKey ${
										sort.key === sortKey ? "chooseBtn" : ""
									}`}
								>
									{sortKey === "receptionDate" ? "시작일 기준" : "마감일 기준"}
								</button>
							))}
							{["asc", "desc"].map(order => (
								<button
									key={order}
									onClick={() => handleSortChange(undefined, order)} // key 값을 undefined로 전달
									className={`sortOrder ${
										sort.order === order ? "chooseBtn" : ""
									}`}
								>
									{order === "asc" ? "오름차순" : "내림차순"}
								</button>
							))}
						</div>
					</div>
				</div>
				{props.selectedOption[0] === "통합" ? (
					<Calendar
						formatDate={formatDate}
						sort={sort}
						sortedPatients={sortedPatients}
						setSortedPatients={setSortedPatients}
						onPatientDataChange={props.onPatientDataChange}
						patientDataNav={patientDataNav}
						patientsDataBoxHeight={patientsDataBoxHeight}
					/>
				) : (
					<>
						{patientDataNav.selected === 0 && (
							<ul className="processLabel">
								{props.dentalProcess.map((item, index) => {
									const filteredOptions = item.options.filter(
										option => !props.hiddenOptions.includes(option),
									);
									return (
										<li
											key={index}
											style={{
												display:
													filteredOptions.length === 0 ? "none" : "block",
											}}
										>
											<p>{item.label}</p>
											<ul className="processOption">
												{filteredOptions.map((option, optionIndex) => (
													<li
														style={{
															width: patientsDataBoxWidth / allOptions.length,
														}}
														key={optionIndex}
													>
														<span>{option}</span>
													</li>
												))}
											</ul>
										</li>
									);
								})}
							</ul>
						)}

						{patientDataNav.selected === 1 && (
							<ul className="tableFormHead">
								<li></li>
								<li>환자 이름</li>
								<li>차트번호</li>
								<li>담당기공소</li>
								<li>접수일</li>
								<li>마감일</li>
								<li>담당의사</li>
								<li>작성자</li>
							</ul>
						)}

						{patientDataNav.selected !== 2 ? (
							<Grid
								sidebarOpen={props.sidebarOpen}
								fullScreenHandle={fullScreenHandle}
								sortedPatients={props.filterdata}
								setSortedPatients={setSortedPatients}
								allOptions={allOptions}
								patientsDataBoxWidth={patientsDataBoxWidth}
								sort={sort}
								setSort={setSort}
								patientDataNav={patientDataNav}
								setPatientDataNav={setPatientDataNav}
								onPatientDataChange={props.onPatientDataChange}
								layout={layout}
								setLayout={setLayout}
								createLayout={createLayout}
								selectedOption={props.selectedOption}
								applyFilters={props.applyFilters}
								dateRange={props.dateRange}
								setChanges={props.setChanges}
								dndStatus={props.dndStatus}
								qhcjfanfwhdfb={qhcjfanfwhdfb} // 보철물 종류
								hiddenOptions={props.hiddenOptions}
							/>
						) : (
							<Calendar
								formatDate={formatDate}
								sort={sort}
								sortedPatients={sortedPatients}
								setSortedPatients={setSortedPatients}
								onPatientDataChange={props.onPatientDataChange}
								patientDataNav={patientDataNav}
								patientsDataBoxHeight={patientsDataBoxHeight}
							/>
						)}

						<div className="writeMoveBtn">
							<button className="newWrite" onClick={handleNavigate}>
								<img
									src={`${process.env.PUBLIC_URL}images/patientDatas/File_dock_light.png`}
									alt=""
								/>
								의뢰서 작성
							</button>
							<button
								onClick={toggleDndStatus}
								className={props.dndStatus ? " saveChart" : "moveChart"}
							>
								<span></span>
								{props.dndStatus ? "의뢰서 고정" : "의뢰서 이동"}
							</button>
						</div>
					</>
				)}
			</FullScreen>
		</div>
	);
}
