import { atom } from "recoil";

// 사용자 정보를 관리하는 아톰 정의
export const userState = atom({
	key: "userState",
	default: {
		userID: "",
		userPW: "",
		// 추가 필드
	},
});
