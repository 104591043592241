import axios from "axios";
import { useState, useEffect } from "react";

const NotificationModal = ({ setNoticeModal, notifications }) => {
	const [buttonStates, setButtonStates] = useState(
		notifications.reduce((acc, notification) => {
			acc[notification.postID] = "확인";
			return acc;
		}, {}),
	);

	// 수정 시간을 기준으로 notifications 배열을 정렬합니다.
	const sortedNotifications = notifications.slice().sort((a, b) => {
		if (a.updatedAt > b.updatedAt) return -1;
		if (a.updatedAt < b.updatedAt) return 1;
		return 0;
	});

	const markPostAsNoticed = async postID => {
		const token = localStorage.getItem("accessToken");
		try {
			const response = await axios.put(
				"/api/main/post/noticed",
				{ postID }, // 요청 바디에 postID를 담아서 보냅니다.
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
				},
			);
			// console.log(response.data);
			// 버튼 상태 업데이트
			setButtonStates(prevState => ({
				...prevState,
				[postID]: "확인완료",
			}));
		} catch (error) {
			console.error("Error marking post as noticed:", error);
		}
	};

	// console.log(notifications);
	return (
		<div className="modalBackground">
			<div className="notificationModal">
				<div className="modalContent">
					<span className="close" onClick={() => setNoticeModal(false)}>
						&times;
					</span>
					<h2>Notifications</h2>
					<ul>
						{sortedNotifications.map(notification => (
							<li key={notification.postID}>
								<p>환자 이름: {notification.patientName}</p>
								<p>기공물 상태: {notification.state}</p>
								<p>
									수정 시간:{" "}
									{notification.updatedAt
										? new Date(notification.updatedAt).toLocaleString()
										: "N/A"}
								</p>
								{buttonStates[notification.postID] !== "확인완료" && (
									<button
										onClick={() => markPostAsNoticed(notification.postID)}
									>
										{buttonStates[notification.postID]}
									</button>
								)}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default NotificationModal;
