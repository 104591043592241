import sampleData from "../../data/sampleData.js";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function HomeSidebar({
	selectedOption,
	setSelectedOption,
	sidebarOpen,
	setSidebarOpen,
	homeData,
}) {
	useEffect(() => {
		if (homeData.length > 0 === false) {
			window.location.reload();
		}
	}, []);

	let urlPost = useLocation();
	const navigate = useNavigate();

	// 중복되지 않는 responsibleLab 추출
	const uniqueResponsibleLabs = [
		...new Set(homeData.map(item => item.responsibleLab)),
	];

	// console.log("homeData",homeData)
	// console.log("uniqueResponsibleLabs",uniqueResponsibleLabs)
	// 로컬 스토리지에 데이터 저장하는 함수
	const saveToLocalStorage = () => {
		// 기존에 저장된 데이터 확인
		const storedData = JSON.parse(
			localStorage.getItem("initialStorageDentalLabs"),
		);

		// 기존에 저장된 데이터가 없는 경우에만 초기화된 데이터를 저장
		// storedData가 없거나 storedData.options가 없는 경우에만 초기화된 데이터를 저장
		if (!storedData) {
			const initialData = [
				{
					label: "원내",
					options: uniqueResponsibleLabs.map(lab => ({
						name: lab,
						hiddenProcesses: [],
					})),
				},
				{
					label: "외주",
					options: [],
				},
			];

			// 초기화된 데이터를 로컬 스토리지에 저장
			localStorage.setItem(
				"initialStorageDentalLabs",
				JSON.stringify(initialData),
			);
		}
	};

	// 컴포넌트가 마운트될 때 로컬 스토리지에 데이터 저장 실행
	useEffect(() => {
		saveToLocalStorage();
	}, []);

	const handleOptionClick = (label, name) => () => {
		setSelectedOption([label, name]);
		//setSelectedLab(name);
		if (urlPost.pathname.startsWith("/post")) {
			navigate("/home");
		}
	};

	// // 중복되지 않는 responsibleLab을 통해 리스트 렌더링
	// const DentalLabList = uniqueResponsibleLabs.map(lab => (
	// 	<li
	// 		key={lab}
	// 		onClick={handleOptionClick("원내", lab)}
	// 		className={selectedOption[1] === lab ? "chooseDental" : ""}
	// 	>
	// 		<p>{lab}</p>
	// 	</li>
	// ));

	const DentalLabList = uniqueResponsibleLabs.map(lab => (
		<li key={lab} onClick={handleOptionClick("원내", lab)}>
			{lab}
		</li>
	));

	const sidebarToggle = () => {
		setSidebarOpen(prev => !prev);
	};

	const [isListOpen, setIsListOpen] = useState(false);

	const toggleList = () => {
		setIsListOpen(!isListOpen);
	};

	return (
		<div className={`homeSidebarSection ${sidebarOpen ? "" : "sidebarClose"}`}>
			<ul>
				<li
					onClick={handleOptionClick("통합", "통합")}
					className={selectedOption[0] === "통합" ? "chooseDental" : ""}
				>
					<h4>통합</h4>
				</li>
				<li className={selectedOption[0] === "원내" ? "chooseDental" : ""}>
					<h4 onClick={toggleList}>원내</h4>
					{isListOpen && <ul>{DentalLabList}</ul>}
				</li>
				<li className={selectedOption[0] === "외주" ? "chooseDental" : ""}>
					<h4>외주</h4>
				</li>
				<li className="addBtn">
					<span>+</span>
					<h4>병원추가</h4>
				</li>
			</ul>
			<div className="sidebarToggle" onClick={sidebarToggle}>
				{sidebarOpen ? "◀" : "▶"}
			</div>
		</div>
	);
}
