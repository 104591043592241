import React from "react";
import Router from "./Router.jsx";


const App = () => {
	return (
		<>
			<Router />
		</>
	);
};

export default App;
