import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormBTN } from "../../components/Button";
import { JoinInput } from "../../components/InputBox";
import Modal from "react-modal";

const LoginPassFind = () => {
	const [userEmail, setUserEmail] = useState({});

	const HandleSubmit = e => {
		e.preventDefault();
		console.log(userEmail);
	};
	const HandleInputChange = e => {
		setUserEmail(e.target.value);
		console.log(userEmail);
	};
	const [modalIsOpen, setIsOpen] = useState(false);

	const ModalHandle = () => {
		setIsOpen(!modalIsOpen);
	};

	return (
		<div className="loginFind loginFindPass contents">
			<h2>
				덴토탈에 가입한 이메일 주소로
				<br />
				비밀번호 재설정 링크가 전송됩니다.
			</h2>
			<JoinInput
				labelfor="이메일"
				span="*"
				placeholder="이메일 주소를 입력하세요"
				onChange={HandleInputChange}
			/>
			<FormBTN
				className="submit"
				type="submit"
				detail="확인"
				onClick={ModalHandle}
			/>
			<Modal
				className="loginPassFindModal"
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: "fixed",
						width: "380px",
						height: "150px",
						top: "50%",
						left: "50%",
						transform: "translate(-50%,-50%)",
						fontSize: "18px",
					},
				}}
			>
				<div className="modalContent">
					<div className="modalMessage" style={{ lineHight: "30px" }}>
						메일이 성공적으로 발송되었습니다.
						<br />
						메일함을 확인해주세요.
					</div>
					<input
						type="button"
						value="확인"
						className="closeBtn"
						onClick={e => {
							ModalHandle();
							HandleSubmit(e);
						}}
					></input>
				</div>
			</Modal>
		</div>
	);
};

export default LoginPassFind;
