// CarrierModal.js
import React from "react";

const JoinPhoneModal = ({ isOpen, onClose, onSelectCarrier }) => {
	if (!isOpen) return null;

	return (
		<div className="loginModatPosition contents" onClick={onClose}>
			<div className="" onClick={e => e.stopPropagation()}>
				<ul className="joinPhoneSelect">
					<li onClick={() => onSelectCarrier("SKT")}>SKT</li>
					<li onClick={() => onSelectCarrier("SKT알뜰폰")}>SKT알뜰폰</li>
					<li onClick={() => onSelectCarrier("KT")}>KT</li>
					<li onClick={() => onSelectCarrier("KT알뜰폰")}>KT알뜰폰</li>
					<li onClick={() => onSelectCarrier("LG U+")}>LG U+</li>
					<li onClick={() => onSelectCarrier("LG U+알뜰폰")}>LG U+알뜰폰</li>
				</ul>
			</div>
		</div>
	);
};

export default JoinPhoneModal;
