import { useEffect, useState } from "react";
import { AddedInputForm } from "../../components/InputBox";

const AddedInput = ({
	label,
	initialOptions,
	placeholder,
	onFieldChange,
	doctorName,
	writer,
}) => {
	// "선택"을 포함한 초기 옵션 상태 설정
	const [options, setOptions] = useState(["선택", ...initialOptions]);
	// 초기 선택된 옵션을 "선택"으로 설정
	const [selectedOption, setSelectedOption] = useState("선택");
	const [showAddNewOptionInput, setShowAddNewOptionInput] = useState(false);

	const onOptionChange = value => {
		if (value === "의사 추가" || value === "작성자 추가") {
			setShowAddNewOptionInput(true);
		} else {
			setSelectedOption(value); // 사용자가 선택한 옵션으로 상태 업데이트
			setShowAddNewOptionInput(false);
			if (value !== "선택") {
				// "선택" 이외의 값이 선택됐을 때만 onFieldChange 호출
				onFieldChange(value);
			}
		}
	};

	const addOption = newOptionName => {
		if (newOptionName && !options.includes(newOptionName)) {
			const updatedOptions = ["선택", ...options, newOptionName];
			setOptions(updatedOptions); // 새 옵션 추가
			setSelectedOption(newOptionName); // 새 옵션을 선택된 옵션으로 설정
			setShowAddNewOptionInput(false);
			onFieldChange(newOptionName); // 새 옵션을 부모 컴포넌트로 전달
		} else {
			alert("유효하지 않거나 이미 존재하는 이름입니다.");
		}
	};
	useEffect(() => {
		const optionToAdd = doctorName || writer; // doctorName이 우선, 없을 경우 writer 사용
		if (optionToAdd && !options.includes(optionToAdd)) {
			setOptions(prevOptions => ["선택", ...prevOptions, optionToAdd]);
		}
		setSelectedOption(optionToAdd || "선택");
	}, [doctorName, writer, options]);

	useEffect(() => {
		// initialOptions가 변경될 때마다 "선택"을 유지하면서 options 상태 업데이트
		setOptions(["선택", ...initialOptions]);
	}, [initialOptions]);

	return (
		<div className="addedBox">
			<AddedInputForm
				item={{ id: label.replace(/\s+/g, "-").toLowerCase(), label }}
				onOptionChange={onOptionChange}
				options={[
					...options,
					label.includes("담당의사") ? "의사 추가" : "작성자 추가",
				]}
				addOption={addOption}
				selectedOption={selectedOption}
				showAddNewOptionInput={showAddNewOptionInput}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default AddedInput;
