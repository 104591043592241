import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useNavigate } from "react-router-dom";
const Calendar = ({
	sort,
	formatDate,
	sortedPatients,
	setSortedPatients,
	onPatientDataChange,
	height,
	patientDataNav,
	patientsDataBoxHeight,
}) => {
	const [calendarEvents, setCalendarEvents] = useState([]);
	const [calendarHeight, setCalendarHeight] = useState(1000); // 초기 높이 설정

	// FullCalendar의 events에 전달할 환자 정보 생성
	useEffect(() => {
		setCalendarEvents(createPatientEvents(sortedPatients));
	}, [sortedPatients]);

	//.patientsDataBox 높이
	useEffect(() => {
		const handleResize = () => {
			const boxElement = document.querySelector(".patientsDataBox");
			const navElement = document.querySelector(".patientsDataBox .nav");
			const calendarHeader = document.querySelector(".fc-header-toolbar");
			if (boxElement && navElement) {
				const boxHeight =
					boxElement.clientHeight -
					navElement.offsetHeight -
					calendarHeader.offsetHeight -
					30; // padding을 포함한 높이

				setCalendarHeight(boxHeight); // Calendar 컴포넌트의 높이를 업데이트
			}
		};
		// console.log(document.querySelector(".fc-header-toolbar").offsetHeight);
		handleResize(); // 컴포넌트 마운트 시 실행
		window.addEventListener("resize", handleResize); // 윈도우 리사이즈 이벤트에 대응

		// console.log(calendarHeight);

		// 컴포넌트 언마운트 시 이벤트 리스너 제거
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const navigate = useNavigate();

	// id값 전달하는 함수
	const handleItemClick = info => {
		// console.log("handleItemClick", info);
		const { event } = info;
		const eventId = event._def.extendedProps?.[0]?.id; // Optional chaining을 사용하여 바로 id에 접근

		// console.log(eventId);
		navigate(`/post/${eventId}`); // eventId가 undefined인 경우를 처리하기 위해 추가 로직이 필요할 수 있음
	};

	// FullCalendar의 이벤트 드래그앤드랍 이벤트 핸들러
	const handleCalendarDrop = info => {
		const { event } = info;
		const newStartDate = formatDate(event.start); //옮겨진 이벤트의 날짜

		// event.extendedProps 객체에서 id 값을 직접 추출하는 간결한 함수
		const findIdValue = extendedProps =>
			Object.values(extendedProps).find(obj => obj.id)?.id;

		// 사용 예
		const idValue = findIdValue(event.extendedProps);

		// 환자 데이터 업데이트
		const updatedPatients = sortedPatients.map(patient => {
			if (patient.postID.toString() === idValue.toString()) {
				return {
					...patient,
					[sort.key]: newStartDate,
				};
			}
			return patient;
		});
		// 상태 업데이트
		setSortedPatients(updatedPatients);

		// 부모 컴포넌트인 Home으로 업데이트된 환자 데이터 전달
		onPatientDataChange(updatedPatients);

		// FullCalendar에 업데이트된 events 전달
		setCalendarEvents(createPatientEvents(updatedPatients));
	};

	// FullCalendar의 events에 전달할 환자 정보를 생성하는 함수
	const createPatientEvents = patients => {
		const events = patients.map(patient => {
			const patientEndDate = patient.deadline.split("T")[0];
			//const sevenDaysAgo = new Date();
			//sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
			//const formattedSevenDaysAgo = formatDate(sevenDaysAgo);
			const daysAgo = day => {
				const today = new Date();
				let daysAgo = new Date(today.setDate(today.getDate() - day));
				return formatDate(daysAgo);
			};

			let borderColor = "#a3a3a3"; // 기본 색상
			let backgroundColor = "white";
			if (
				patientEndDate <= daysAgo(-7) &&
				patientEndDate > daysAgo(-3) &&
				patient.state !== "완료" &&
				patient.state !== "리메이크"
			) {
				// 마감일이 오늘로부터 7일 이내라면 오렌지 테투리
				backgroundColor = "rgb(249 212 140)";
				borderColor = "orange";
			} else if (
				patientEndDate >= daysAgo(0) &&
				patientEndDate <= daysAgo(-3) &&
				patient.state !== "완료" &&
				patient.state !== "리메이크"
			) {
				//마감일이 오늘로부터 3일 이내라면 오렌지 테투리
				backgroundColor = "rgb(249 212 140)";
				borderColor = "orange";
			} else if (patient.state === "완료" || patient.state === "리메이크") {
				//처리 완료 된것들 초록색 테두리
				backgroundColor = "rgb(209 240 159)";
				borderColor = "rgb(70 139 18)";
			} else if (
				patientEndDate < daysAgo(0) &&
				patient.state !== "완료" &&
				patient.state !== "리메이크"
			) {
				//날짜가 지나도 처리 안된것들 빨간 테두리
				borderColor = "rgb(201 87 79)";
				backgroundColor = "rgb(240 163 159)";
			}

			return {
				title: `${patient.patientName} #${patient.chartNumber}\n 담당자 : ${patient.writer}\n  상태 : ${patient.state}`,
				// \n
				// 기간 : ${
				// 	patient.receptionDate.split(" ")[0]
				// } ~ ${patient.deadline.split(" ")[0]}`
				// ,
				date: patient[sort.key], //정렬기준 시작,마감일
				extendedProps: [
					{ id: patient.postID },
					{ chartNumber: patient.chartNumber },
					{ writer: patient.writer },
					{ responsibleLab: patient.responsibleLab },
					{ receptionDate: patient.receptionDate },
					{ deadline: patient.deadline },
				],
				allDay: true,
				backgroundColor,
				borderColor,
				textColor: "white",
			};
		});
		return events;
	};
	return (
		<FullCalendar
			locale="kr"
			plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
			initialView="dayGridMonth"
			headerToolbar={{
				start: "today",
				center: "title",
				end: "prev,next",
			}}
			events={calendarEvents}
			editable={false} //d&d 비활성화
			eventDrop={handleCalendarDrop}
			eventClick={handleItemClick}
			//height={calendarHeight}
			//height={patientsDataBoxHeight}
		/>
	);
};

export default Calendar;
