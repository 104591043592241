import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import LoginIdFind from "./pages/login/LoginIdFind";
import LoginPassFind from "./pages/login/LoginPassFind";

import "./style/main.scss";

import Main from "./pages/main/Main";
import Timers from "./Timers.jsx";
import { JoinCompanyInfo } from "./pages/join/JoinCompanyInfo.jsx";
import { JoinUserInfo } from "./pages/join/JoinUserInfo.jsx";
import { Home } from "./pages/home/Home.jsx";
import { HomeLayout } from "./layout/HomeLayout.jsx";
import { MainLayout } from "./layout/MainLayout.jsx";
import Post from "./pages/post/Post.jsx";
import axios from "axios";

const Router = () => {
	const [dndStatus, setDndStatus] = useState(false);
	const [selectedOption, setSelectedOption] = useState(() => {
		const savedOption = localStorage.getItem("selectedOption");
		return savedOption ? JSON.parse(savedOption) : ["통합", "통합"];
	});

	useEffect(() => {
		// selectedOption 상태가 변경될 때마다 로컬 스토리지에 저장
		localStorage.setItem("selectedOption", JSON.stringify(selectedOption));
	}, [selectedOption]);
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [homeData, setHomeData] = useState([]); // 게시글 데이터를 저장할 상태
	const [postData, setPostData] = useState([]); // 게시글 데이터를 저장할 상태
	const [loading, setLoading] = useState(true); // 데이터 로딩 상태를 저장할 상태

	useEffect(() => {
		// 게시글 데이터를 가져오는 함수
		const fetchPosts = async () => {
			try {
				const token = localStorage.getItem("accessToken");
				const response = await axios.get("/api/main/post", {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.data.posts && response.data.posts.length > 0) {
					setHomeData(response.data.posts); // 응답 데이터를 상태에 저장
					setPostData(response.data); // 응답 데이터를 상태에 저장
				} else {
					console.error("게시글이 비어 있습니다.");
				}
			} catch (error) {
				console.error("게시글을 가져오는 중 오류가 발생했습니다:", error);
			} finally {
				setLoading(false); // 데이터 로딩이 완료되었음을 표시
			}
		};

		fetchPosts(); // 함수 호출
	}, []); // 의존성 배열을 빈 배열로 설정하여 컴포넌트 마운트 시에만 호출되도록 함

	// 데이터 로딩 중이면 로딩 표시를 보여줍니다.
	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<Routes>
			<Route
				element={
					<HomeLayout
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
						sidebarOpen={sidebarOpen}
						setSidebarOpen={setSidebarOpen}
						homeData={homeData}
						dndStatus={dndStatus}
					/>
				}
			>
				<Route
					path="/home"
					element={
						<Home
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
							homeData={homeData}
							sidebarOpen={sidebarOpen}
							dndStatus={dndStatus}
							setDndStatus={setDndStatus}
						/>
					}
				/>
				<Route path="/post" element={<Post postData={postData} />} />
				<Route path="/post/:id" element={<Post postData={postData} />} />
			</Route>
			{/* </Route> */}
			{/* MainLayout 내의 다른 경로들 */}
			<Route element={<MainLayout />}>
				<Route path="/postTest" element={<Post />} />
				<Route path="/" element={<Main />} />
				<Route path="/test" element={<Timers />} />
				<Route path="/join" element={<JoinUserInfo />} />
				<Route path="/join/companyinfo" element={<JoinCompanyInfo />} />
				<Route path="/login" element={<Login />} />
				<Route path="/login/idfind" element={<LoginIdFind />} />
				<Route path="/login/passfind" element={<LoginPassFind />} />
				{/* MainLayout을 사용하는 다른 경로 추가 가능 */}
			</Route>
		</Routes>
	);
};

export default Router;
