const dentalLabss = [
	{
		label: "원내",
		options: [
			{ name: "1층기공소", processes: [] }, // 개별 기공소별 프로세스
			{ name: "2층기공소", processes: [] },
			{ name: "3층기공소", processes: [] },
		],
	},
	{
		label: "외주",
		options: [
			{ name: "김포기공소", processes: [] },
			{ name: "서울기공소", processes: [] },
			{ name: "경기기공소", processes: [] },
		],
	},
];

const DentalProcess = [
	{
		label: "치과출고",
		options: ["기공의뢰서", "수거요청"],
	},
	{
		label: "기공소 수령",
		options: ["보류", "모델체크", "의사확인", "기공소 제작중"],
	},
	{
		label: "기공소 출고",
		options: ["기공물 배송중"],
	},
	{
		label: "치과 수령",
		options: ["완료", "리메이크"],
	},
];

const PatientsInSource = [
	{
		key: 1222,
		name: "이재연",
		chartNum: 123111111111,
		state: "모델체크",
		manager: "재롱둥이",
		dentalLabGroup: "원내",
		dentalLab: "1층기공소",
		startDate: "2024-01-01",
		endDate: "2024-01-21",
	},

	{
		key: 3456,
		name: "박지영",
		chartNum: 789333333333,
		state: "기공소 제작중",
		manager: "스마일",
		dentalLabGroup: "원내",
		dentalLab: "2층기공소",
		startDate: "2023-11-03",
		endDate: "2023-11-23",
	},

	{
		key: 5678,
		name: "장현서",
		chartNum: 123555555555,
		state: "기공소 제작중",
		manager: "긍정맨",
		dentalLabGroup: "원내",
		dentalLab: "3층기공소",
		startDate: "2024-03-05",
		endDate: "2024-03-25",
	},
	{
		key: 6789,
		name: "신지우",
		chartNum: 456666666666,
		state: "의사확인",
		manager: "즐거운",
		dentalLabGroup: "외주",
		dentalLab: "경기기공소",
		startDate: "2023-12-06",
		endDate: "2023-12-26",
	},
	{
		key: 7890,
		name: "김서연",
		chartNum: 789777777777,
		state: "모델체크",
		manager: "웃는얼굴",
		dentalLabGroup: "원내",
		dentalLab: "1층기공소",
		startDate: "2024-01-07",
		endDate: "2024-01-27",
	},

	{
		key: 9012,
		name: "박태린",
		chartNum: 123999999999,
		state: "보류",
		manager: "유머러스",
		dentalLabGroup: "원내",
		dentalLab: "2층기공소",
		startDate: "2024-02-09",
		endDate: "2024-02-29",
	},

	{
		key: 1112,
		name: "최정우",
		chartNum: 789111111111,
		state: "모델체크",
		manager: "신나는",
		dentalLabGroup: "원내",
		dentalLab: "3층기공소",
		startDate: "2023-10-11",
		endDate: "2023-10-21",
	},

	{
		key: 1314,
		name: "김성훈",
		chartNum: 123131313131,
		state: "보류",
		manager: "즐거운",
		dentalLabGroup: "원내",
		dentalLab: "1층기공소",
		startDate: "2024-02-13",
		endDate: "2024-02-23",
	},

	{
		key: 1516,
		name: "장세영",
		chartNum: 789151515151,
		state: "모델체크",
		manager: "웃는얼굴",
		dentalLabGroup: "원내",
		dentalLab: "2층기공소",
		startDate: "2024-02-15",
		endDate: "2024-02-25",
	},

	{
		key: 1718,
		name: "김하늘",
		chartNum: 123171717171,
		state: "보류",
		manager: "긍정맨",
		dentalLabGroup: "원내",
		dentalLab: "3층기공소",
		startDate: "2024-02-17",
		endDate: "2024-02-27",
	},

	{
		key: 1920,
		name: "송지우",
		chartNum: 789191919191,
		state: "모델체크",
		manager: "밝은미소",
		dentalLabGroup: "원내",
		dentalLab: "1층기공소",
		startDate: "2024-02-19",
		endDate: "2024-02-29",
	},
];

const PatientsOutSource = [
	{
		key: 2345,
		name: "김민수",
		chartNum: 456222222222,
		state: "보류",
		manager: "활기찬",
		dentalLabGroup: "외주",
		dentalLab: "김포기공소",
		startDate: "2024-01-02",
		endDate: "2024-01-22",
	},
	{
		key: 1819,
		name: "이지훈",
		chartNum: 456181818181,
		state: "보류",
		manager: "활기찬",
		dentalLabGroup: "외주",
		dentalLab: "경기기공소",
		startDate: "2023-11-18",
		endDate: "2023-11-28",
	},
	{
		key: 1617,
		name: "최민혁",
		chartNum: 987161616161,
		state: "기공소 제작중",
		manager: "즐거운",
		dentalLabGroup: "외주",
		dentalLab: "서울기공소",
		startDate: "2024-03-16",
		endDate: "2024-03-26",
	},
	{
		key: 1415,
		name: "서영우",
		chartNum: 456141414141,
		state: "보류",
		manager: "유쾌",
		dentalLabGroup: "외주",
		dentalLab: "김포기공소",
		startDate: "2024-01-14",
		endDate: "2024-01-24",
	},
	{
		key: 563753783,
		name: "재연이",
		chartNum: 112313,
		state: "수거요청",
		manager: "재롱둥이",
		dentalLabgrooup: "외주",
		dentalLab: "서울기공소",
		startDate: "2023-12-01",
		endDate: "2023-12-07",
	},
	{
		key: 1213,
		name: "박진희",
		chartNum: 987121212121,
		state: "기공소 제작중",
		manager: "행복한",
		dentalLabGroup: "외주",
		dentalLab: "경기기공소",
		startDate: "2024-02-12",
		endDate: "2024-02-22",
	},
	{
		key: 1011,
		name: "이지민",
		chartNum: 456101010101,
		state: "수거요청",
		manager: "사랑스러운",
		dentalLabGroup: "외주",
		dentalLab: "서울기공소",
		startDate: "2024-02-10",
		endDate: "2024-02-20",
	},
	{
		key: 8901,
		name: "이주원",
		chartNum: 987888888888,
		state: "기공소 제작중",
		manager: "밝은미소",
		dentalLabGroup: "외주",
		dentalLab: "김포기공소",
		startDate: "2024-02-08",
		endDate: "2024-02-28",
	},
	{
		key: 4567,
		name: "이승우",
		chartNum: 987444444444,
		state: "보류",
		manager: "유쾌",
		dentalLabGroup: "외주",
		dentalLab: "서울기공소",
		startDate: "2024-02-04",
		endDate: "2024-02-24",
	},
];

export default {
	PatientsOutSource,
	PatientsInSource,
	dentalLabss,
	DentalProcess,
};
