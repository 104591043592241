import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const MainFooter = () => {
	return (
		<footer className="mainFooter">
			<div className="footerContents">
				<div className="funnelsInfo">
					<div className="companyInfo">
						<h1>
							<img
								src={`${process.env.PUBLIC_URL}/images/dentotal_main_logo_white
						.png`}
								alt="덴토탈"
							/>
						</h1>
						<p>주식회사 퍼널스 | 대표자 : 원종현</p>
						<p>사업자 등록번호 : 127-88-03197</p>
						<p>통신판매업신고번호 : 20000-0000-0000호</p>
						<p>주소 : 서울특별시 영등포구 당산로38길 12, 4층 전체</p>
					</div>
					<div className="companyContact">
						<p>이메일 : funnels0703@gmail.com</p>
						<p>대표전화 : 02-3144-3868</p>
						<p>팩스 : 02-3144-3870</p>
					</div>
					<p className="copyRight">
						Copyright ⓒ 2024 FUNNELS. ALL RIGHTS RESERVED
					</p>
				</div>
				<nav>
					<ul className="fnb">
						<li>
							서비스 소개
							<ul>
								<li>급여관리</li>
								<li>전자계약</li>
								<li>급여관리</li>
								<li>전자계약</li>
								<li>급여관리</li>
								<li>전자계약</li>
							</ul>
						</li>
						<li>
							가격정책
							<ul>
								<li>급여관리</li>
								<li>전자계약</li>
							</ul>
						</li>
						<li>
							바우처
							<ul>
								<li>급여관리</li>
								<li>전자계약</li>
							</ul>
						</li>
						<li>블로그</li>
						<li>덴토탈 소개</li>
					</ul>
				</nav>
			</div>
		</footer>
	);
};

export default MainFooter;
