import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function JoinInput(props) {
	const {
		labelfor,
		span,
		placeholder,
		LinkDetail,
		value,
		onChange,
		name,
		type,
	} = props;

	const isInputEmpty = value && value.trim() === "";
	const shouldShowWarning = span && isInputEmpty;

	return (
		<div className="joinCompanyInput">
			<div className="InputBtw">
				<label>
					{labelfor}
					{span && <span className="star">{span}</span>}
				</label>
				{LinkDetail && <Link to="/join/userinfo">{LinkDetail}</Link>}
			</div>
			<input
				className={`${shouldShowWarning ? "warning" : ""}`}
				type={type ? type : "text"}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
			{shouldShowWarning && (
				<span className="waringMessage">필수 입력사항입니다.</span>
			)}
		</div>
	);
}

export function JoinUserInputBox(props) {
	return (
		<div className="joinRelative">
			{props.imgsrc && (
				<img
					className="joinImgs"
					src={`${process.env.PUBLIC_URL}/images/login/${props.imgsrc}`}
					alt=""
				/>
			)}
			<input
				className={`joinUserInputBox ${props.className}`}
				type={props.type}
				name={props.name}
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.handleInputChange}
			/>
		</div>
	);
}

export function PostHeaderInput({ item, value, onChange, labNames, postID }) {
	const [options, setOptions] = useState(["선택", ...labNames]);

	useEffect(() => {
		setOptions(["선택", ...labNames]);
	}, [labNames]); // labNames가 변경될 때마다 옵션 목록 업데이트

	// 인풋 입력 필드 타입 결정
	const inputType =
		item.id === "receptionDate" || item.id === "deadline"
			? "date"
			: item.id === "chartNumber"
				? "text"
				: "text"; // chartNumber 및 기본값으로 "text" 설정

	// 날짜 형식을 변환하는 함수
	const formatDate = date => {
		const isoDate = new Date(date).toISOString();
		return isoDate.substring(0, 10); // YYYY-MM-DD 형식으로 반환
	};

	// deadline 필드이고 postID가 존재하는 경우 입력 필드를 비활성화
	const isDisabled = item.id === "deadline" && postID;

	return (
		<>
			<label htmlFor={item.id}>{item.label}</label>
			{item.id === "responsibleLab" ? (
				<select
					id={item.id}
					value={value}
					onChange={e => onChange(item.id, e.target.value)}
					// disabled={isDisabled} // select에 대한 disabled 처리
				>
					{options.map((option, index) => (
						<option key={index} value={option}>
							{option}
						</option>
					))}
				</select>
			) : (
				<input
					type={inputType}
					id={item.id}
					value={inputType === "date" ? formatDate(value) : value}
					onChange={e => onChange(item.id, e.target.value)}
					// readOnly={isDisabled} // input에 대한 readOnly 처리
					placeholder="* 필수입력 사항입니다."
				/>
			)}
		</>
	);
}

export function PostRadioBox({ item, radioType, onChange, selectedValue }) {
	return (
		<>
			<input
				type="radio"
				name={radioType}
				id={item.id}
				value={item.id}
				checked={selectedValue === item.id.toString()} // 타입을 맞추기 위해 toString() 사용
				onChange={e => onChange(radioType, e.target.value)}
			/>

			<label htmlFor={item.id}>{item.label}</label>
		</>
	);
}

export function PostFootRadioBox({ item, radioType, onChange, selectedValue }) {
	return (
		<>
			<input
				type="radio"
				name={radioType}
				id={item.id}
				value={item.label}
				checked={selectedValue === item.label} // 현재 선택된 값과 일치할 경우 체크됩니다.
				onChange={e => onChange(radioType, e.target.value)} // 변경사항을 상위 컴포넌트로 전달
			/>
			<label htmlFor={item.id}>{item.label}</label>
		</>
	);
}

export function PostmainInput({ item, radioType, onChange, isChecked }) {
	const handleChange = e => {
		onChange(radioType, { id: item.id, checked: e.target.checked });
	};

	return (
		<>
			<input
				type="checkbox"
				id={item.id}
				onChange={handleChange}
				name={radioType} // 고유한 이름을 사용하여 그룹화합니다.
				checked={isChecked} // 이 체크박스의 체크 상태를 결정합니다.
			/>
			<label htmlFor={item.id}>{item.label}</label>
		</>
	);
}

export function AddedInputForm({
	item,
	onOptionChange,
	options,
	addOption,
	selectedOption,
	showAddNewOptionInput,
	placeholder,
}) {
	const [newOption, setNewOption] = useState("");
	const handleKeyPress = e => {
		if (e.key === "Enter") {
			// 엔터 키가 눌렸는지 확인
			e.preventDefault(); // 폼 제출 이벤트 방지
			addOption(newOption); // 새 옵션 추가 함수 호출
			setNewOption(""); // 입력 필드 초기화
		}
	};

	// 입력 필드와 추가 버튼을 포함한 조건부 렌더링 로직
	const renderAddNewOptionInput = () => (
		<>
			<input
				className="addedInputSelect selectA"
				type="text"
				placeholder={placeholder}
				value={newOption}
				onChange={e => setNewOption(e.target.value)}
				onKeyPress={handleKeyPress} // 엔터 키 이벤트 핸들러 추가
			/>
			{/* <button
				onClick={() => {
					addOption(newOption); // 추가 버튼 클릭 시 새 옵션 추가
					setNewOption(""); // 입력 필드 초기화
				}}
			>
				추가
			</button> */}
		</>
	);

	return (
		<div className="flexRow">
			<label className="AddedLabel" htmlFor={item.id}>
				{item.label}
			</label>
			<div>
				<select
					id={item.id}
					value={selectedOption}
					onChange={e => onOptionChange(e.target.value)}
					className="addedInputSelect"
				>
					{options?.map(
						(
							option,
							index, // 옵셔널 체이닝 사용
						) => (
							<option key={index} value={option}>
								{option}
							</option>
						),
					)}
				</select>
				{showAddNewOptionInput && renderAddNewOptionInput()}
			</div>
		</div>
	);
}
