import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
	PostFootRadioBox,
	PostHeaderInput,
	PostRadioBox,
	PostmainInput,
} from "../../components/InputBox";
import Cltlr from "./Cltlr";
import Canvas from "./Canvas";

import { PostHeaderInputData, PonticType, xmfpdl } from "../../utils/postData";
import Chat from "./Chat";
import AddedInput from "./AddedInput";
import axios from "axios";
import { ToggleButton } from "../../components/Button";
import PasteEventListener from "./PasteEventListener";

const Post = ({ postData }) => {
	const accessToken = localStorage.getItem("accessToken");
	const initialStorageDentalLabs = JSON.parse(
		localStorage.getItem("initialStorageDentalLabs"),
	);

	const navigate = useNavigate();
	const { id } = useParams();
	const [doctorNames, setDoctorNames] = useState([]); // 의사 이름
	const [writerNames, setWriterNames] = useState([]); // 작성자 이름
	const [labNames, setLabNames] = useState([]); // 기공소 이름
	const [prosthodonticTypes, setProsthodonticTypes] = useState([]); // 기공물 종류

	const [isChatOpen, setIsChatOpen] = useState(false);
	const [isOn, setIsOn] = useState(false); // 파일 첨부 토글 초기 상태는 꺼진 상태로 설정
	const today = new Date().toISOString().split("T")[0];
	const [selectedNumbers, setSelectedNumbers] = useState([]);
	const [checkedFiles, setCheckedFiles] = useState({});
	const [allChecked, setAllChecked] = useState(false);
	const [postDetailData, setPostDetailData] = useState({
		doctorName: "", // 의사 이름
		writer: initialStorageDentalLabs[2].writer.name, // 작성자 이름
		patientName: "",
		chartNumber: "",
		responsibleLab: "",
		receptionDate: today,
		deadline: today,
		// receptionDate: "2022-12-25:00:00.000Z",
		// deadline: "2022-12-25:00:00.000Z",
		prosthesisType:
			prosthodonticTypes.length > 0 ? prosthodonticTypes[4].id : "",
		cltlr: selectedNumbers,
		restorationType: "", // 보철물 종류
		attachmentUrl: "",
		drawingBoard: {
			none: [], // 배경없음
			occlusal: [], // 상하악교합면
			frontal: [], // 정면
			buccal: [], // 구치부설측
			buccalSide: [], // 구치부단면
			lingualSide: [], // 구치부순측
		},
		shade: {
			basic: "vita classic",
			first: "",
			second: "",
			third: "",
			xnaudcmd: "",
		},
		ponticType: "",
		trayType: "",
		chat: [], // 채팅 메시지 배열 추가
		notes: "",
		drawingBoardImage: "",
	});

	// 채팅 정보 받아오기
	const handleNewMessage = newMessage => {
		setPostDetailData(prevData => {
			// chatMessages가 배열이 아니면 빈 배열로 초기화
			const chatMessages = Array.isArray(prevData.chat) ? prevData.chat : [];
			return {
				...prevData,
				chat: [...chatMessages, newMessage],
			};
		});
	};

	// 필드 상태를 업데이트하는 통합 함수
	const handleFieldChange = (field, value) => {
		setPostDetailData({ ...postDetailData, [field]: value });
	};

	const handleAllCheck = checked => {
		setAllChecked(checked);
		const newCheckedFiles = {};
		files.forEach((file, index) => {
			newCheckedFiles[index] = checked;
		});
		setCheckedFiles(newCheckedFiles);
	};

	// 보철물 종류 체크박스 함수
	const handleCheckboxChange = (field, { id, checked }) => {
		setPostDetailData(prevData => {
			// 이미 선택된 항목이 있다면, 해당 항목을 제거하거나 추가합니다.
			const updatedArray = checked
				? [...(prevData[field] || []), id] // 체크되면 추가
				: (prevData[field] || []).filter(item => item !== id); // 체크 해제되면 제거

			return { ...prevData, [field]: updatedArray };
		});
	};

	// 파일 체크박스 함수, 선택된 파일 삭제
	const handleFileCheckboxChange = index => {
		const newCheckedFiles = { ...checkedFiles, [index]: !checkedFiles[index] };
		setCheckedFiles(newCheckedFiles);
		// 전체 선택 상태 검사
		setAllChecked(Object.values(newCheckedFiles).every(Boolean));
	};

	// 선택된 파일 삭제 핸들러
	const handleRemoveSelectedFiles = () => {
		// 체크된 파일들만 필터링하여 제거
		const newFiles = files.filter((_, index) => !checkedFiles[index]);
		setFiles(newFiles);
		// 체크 상태 초기화
		setCheckedFiles({});
		setAllChecked(false);
	};

	// shade 상태를 업데이트하는 함수
	const handleShadeChange = (field, value) => {
		setPostDetailData(prevData => ({
			...prevData,
			shade: {
				...prevData.shade,
				[field]: value,
			},
		}));
	};

	// drawingBoard를 업데이트하는 함수
	const handleDrawingBoardChange = data => {
		setPostDetailData(prevData => ({
			...prevData,
			drawingBoard: data,
		}));
	};
	// 노트를 업데이트 하는 함수
	const handleNotesChange = e => {
		setPostDetailData({
			...postDetailData,
			notes: e.target.value,
		});
	};

	// 그림판 이미지 업로드

	const [drawingBoardImageFile, setDrawingBoardImageFile] = useState([]);

	useEffect(() => {
		if (postDetailData.drawingBoardImage) {
			const imageUrls = postDetailData.drawingBoardImage.split(",");
			const imageObjects = imageUrls.map(url => {
				const match = url.match(/\d{10,}/); // 숫자 10자리 이상만 추출
				const id = match ? match[0] : Date.now() + Math.random(); // match가 null이면 오늘날짜 + 랜덤숫자 반환

				if (url.includes("image")) {
					return {
						type: "image",
						url: url,
						id: id,
					};
				} else if (url.includes("video")) {
					return {
						type: "video",
						url: url,
						id: id,
					};
				}
			});
			// 중복된 id를 제거하는 과정 추가
			const uniqueImageObjects = [];
			const idSet = new Set();

			for (const obj of imageObjects) {
				if (!idSet.has(obj.id)) {
					uniqueImageObjects.push(obj);
					idSet.add(obj.id);
				}
			}
			setDrawingBoardImageFile(uniqueImageObjects);
			// --------------------------------------------------------
			// --------------------------------------------------------
		}
	}, [postDetailData.drawingBoardImage]);
	// console.log("drawingBoardImageFile", drawingBoardImageFile); // 왜 빈값 ?

	//파일 업로드
	const [files, setFiles] = useState([]);
	const [inputKey, setInputKey] = useState(Date.now());

	const [fileUrls, setFileUrls] = useState([]);
	// 	, https://기준으로 자르고
	// console.log("files", files);
	// console.log("fileUrls", fileUrls);
	useEffect(() => {
		//useEffect안쓰면 렌더링 많이 댐
		if (postDetailData.attachmentUrl) {
			const splitFileUrls = postDetailData.attachmentUrl.split(", https://");

			const modifiedUrls = splitFileUrls.map(url => {
				if (!url.includes("https://")) {
					url = "https://" + url;
				}
				return url;
			});

			setFileUrls(modifiedUrls);

			const splitFileNames = splitFileUrls.map(url => {
				const match = url.match(/\d{10,}-(.*)/); //숫자 10자리 이상 과 -
				const fileName = match ? match[1] : ""; // match가 null이면 빈 문자열 반환
				const file = new File([], fileName); // 빈 File 객체 생성
				return file;
			});

			setFiles(files => [...files, ...splitFileNames]); // 기존 파일 배열에 새 파일 배열 추가
		}
	}, [postDetailData.attachmentUrl]);

	const handleFileChange = event => {
		const newFiles = Array.from(event.target.files); // FileList를 배열로 변환
		setFiles(files => [...files, ...newFiles]); // 기존 파일 배열에 새 파일 배열 추가
		setInputKey(Date.now());
	};

	const handleSubmit = async () => {
		// 필수 필드가 비어 있는지 확인
		const requiredFields = {
			doctorName: "의사 이름",
			writer: "작성자",
			patientName: "환자 이름",
			chartNumber: "차트 번호",
			responsibleLab: "담당 연구실",
		};
		const emptyFields = Object.keys(requiredFields)
			.filter(
				field => !postDetailData[field] || postDetailData[field] === "선택",
			)
			.map(key => requiredFields[key]); // 비어 있거나 "선택"인 필드의 이름을 사람이 읽을 수 있는 형태로 변환

		// 비어 있는 필드가 있다면 경고 메시지를 표시하고 함수 실행을 중지합니다.
		if (emptyFields.length > 0) {
			alert(`${emptyFields.join(", ")}를(을) 입력해주세요.`);
			return;
		}

		const confirm = window.confirm("저장하시겠습니까?");

		// 사용자가 '예'를 선택한 경우
		if (confirm) {
			const formData = new FormData();

			Object.keys(postDetailData).forEach(key => {
				if (
					typeof postDetailData[key] === "object" &&
					postDetailData[key] !== null
				) {
					// 객체 타입의 데이터는 JSON 문자열로 변환하여 추가
					formData.append(key, JSON.stringify(postDetailData[key]));
				} else {
					// 기타 데이터는 직접 추가
					formData.append(key, postDetailData[key]);
				}
			});
			formData.append("realWriter", initialStorageDentalLabs[2].writer.name);

			files.forEach(file => {
				const encodedFileName = encodeURIComponent(file.name); // 파일 이름 인코딩
				formData.append("files[]", file, encodedFileName);
			});

			// drawingBoardImage.forEach(item => {
			// 	const file = item.content;
			// 	const encodedFileName = encodeURIComponent(file.name);
			// 	formData.append("drawingBoardImage", file, encodedFileName);
			// });

			drawingBoardImageFile.forEach(item => {
				if (item.content instanceof File) {
					const file = item.content;
					const encodedFileName = encodeURIComponent(file.name);
					formData.append("drawingBoardImage", file, encodedFileName);
				} else {
					// 파일 객체가 아닌 경우에 대한 처리 (파일 경로를 append하거나 다른 작업 수행)
					formData.append("drawingBoardImage", item.url); // 예시: 파일 경로를 직접 append
				}
			});

			try {
				// console.log("post 보내주는 데이터 : ", postDetailData);
				const config = {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				};

				let response;
				if (id) {
					// 기존 포스트 수정
					response = await axios.put(`/api/main/post/${id}`, formData, config);
				} else {
					// 새 포스트 생성
					response = await axios.post(
						"/api/main/post?type=1",
						formData,
						config,
					);
				}

				//추가보철물 있으면 POST 요청으로 새 보철물 생성
				if (newProsthesisArray.length > 0) {
					// console.log("newProsthesisArray", newProsthesisArray);
					try {
						response = await axios.post(
							`/api/main/post?type=2`,
							newProsthesisArray,
							config,
						);
						//alert("보철물 추가 데이터 전송 성공!");
					} catch (error) {
						console.error("데이터 전송 에러:", error);
						alert("보철물 추가 데이터 전송 에러");
					}
				}

				// console.log("서버 응답:", response.data);
				navigate("/home");
				window.location.reload(); // 페이지 새로고침
			} catch (error) {
				console.error("데이터 전송 에러:", error);
				// 에러 처리 로직...
			}
		}
	};
	// 채팅 창을 토글하는 함수
	const toggleChat = () => {
		setIsChatOpen(!isChatOpen);
	};

	useEffect(() => {
		// 문자열로 변환된 값을 postData에 설정
		setPostDetailData(prevData => ({
			...prevData,
			cltlr: selectedNumbers,
		}));
	}, [selectedNumbers]); // selectedNumbers가 변경될 때마다 useEffect 트리거

	// 선택한 기공물 - 보철물 정제된 데이터
	const selectedProsthodonticType = prosthodonticTypes.find(
		type => type.id.toString() === postDetailData.prosthesisType,
	);

	const [tmfepdlxj, setTmfepdlxj] = useState();

	//보철물 id값 중 max값 뽑기
	const [maxId, setMaxId] = useState(-Infinity);

	prosthodonticTypes.forEach(item => {
		item.prosthesisTypes.forEach(type => {
			if (type.id > maxId) {
				setMaxId(type.id);
			}
		});
	});
	//전송할 기타 배열
	const [newProsthesisArray, setNewProsthesisArray] = useState([]);
	useEffect(() => {
		// 기공물 종류가 변경될 때 실행
		const updatedProsthesisTypes = selectedProsthodonticType
			? selectedProsthodonticType.prosthesisTypes.concat(
					newProsthesisArray.filter(
						p => p.prosthodonticId === selectedProsthodonticType.id,
					),
				)
			: [];

		setTmfepdlxj(updatedProsthesisTypes);
	}, [selectedProsthodonticType, newProsthesisArray]); // newProsthesisArray와 selectedProsthodonticType 의존성 추가

	const [customProsthesis, setCustomProsthesis] = useState(""); // 새로운 보철물을 위한 상태 추가

	// console.log("11", newProsthesisArray);
	// console.log("22", selectedProsthodonticType);
	// console.log("33", prosthodonticTypes);
	// console.log("44", tmfepdlxj);

	const handdleAddqhcjfEtc = () => {
		//기공물 선택에 따른 정제된 보철물들 업데이트

		const newProsthesis = {
			id: maxId + 1,
			name: customProsthesis,
			prosthodonticId: selectedProsthodonticType.id,
		};

		setTmfepdlxj(prev => [...prev, newProsthesis]); //input 으로 보여줄 보철물 배열

		setNewProsthesisArray(prev => [...prev, newProsthesis]); //서버로 전송할 새로운 보철물 배열

		setPostDetailData(prevData => ({
			...prevData,
			restorationType: [...prevData.restorationType, (maxId + 1).toString()],
			// 기타 보철물에 대한 정보도 추가
			prosthesisType: prevData.prosthesisType, // 현재 선택된 기공물 유지 (이거 안하니까 기타 추가하면 prosthesisType이 계속 초기값으로 변경됨)
		}));

		//updateProsthodonticTypes(newProsthesis); //prosthodonticTypes 보철물 추가 반영
		//prostETC();

		setCustomProsthesis(""); //기타란 초기화
		setMaxId(prev => prev + 1);
	};

	// console.log(postDetailData.restorationType);

	const [clipBoardCanvasActive, setClipBoardCanvasActive] =
		useState("clipBoard");

	useEffect(() => {
		if (postData && postData.posts) {
			// 의사 이름 추출 및 중복 제거
			const doctorNames = postData.posts.map(post => post.doctorName);
			const uniqueDoctorNames = Array.from(new Set(doctorNames));
			setDoctorNames(uniqueDoctorNames);

			// 작성자 이름 추출 및 중복 제거
			const writerNames = postData.posts.map(post => post.writer);
			const uniqueWriterNames = Array.from(new Set(writerNames));
			setWriterNames(uniqueWriterNames);

			// 담당 기공소 추출 및 중복 제거
			const labNames = postData.posts.map(post => post.responsibleLab);
			const uniqueLabNames = Array.from(new Set(labNames));
			setLabNames(uniqueLabNames); // setLabNames는 상태 업데이트 함수입니다. 상태를 선언해주세요.

			// 기공물 종류
			const prosthodonticsData = postData.prosthodonticsWithTypes.map(
				prosthodontic => ({
					id: prosthodontic.ProsthodonticID,
					name: prosthodontic.Name,
					prosthesisTypes: prosthodontic.ProsthesisTypes.map(prosthesis => ({
						id: prosthesis.ProsthesisTypeID,
						name: prosthesis.Name,
						prosthodonticId: prosthesis.ProsthodonticID,
					})),
				}),
			);

			setProsthodonticTypes(prosthodonticsData);
		} // 데이터가 있을때 실행한다
	}, [postData]); // postData가 변경될 때마다 실행

	//id에 맞는 데이터 정제
	useEffect(() => {
		// console.log("postData.posts", postData.posts);
		if (id && postData.posts) {
			// console.log("postData.posts", postData.posts);

			// URL에서 받은 id와 일치하는 게시물을 찾습니다.
			const foundPost = postData.posts.find(
				post => post.postID.toString() === id,
			);
			const parseJsonSafely = (jsonString, fallback) => {
				try {
					// 빈 문자열 검사를 추가합니다.
					if (jsonString === "") {
						return "";
					}
					// 올바른 JSON 형식인 경우 파싱을 시도합니다.
					return JSON.parse(jsonString);
				} catch (error) {
					// JSON 형식이 올바르지 않은 경우 오류 메시지를 출력하고, 대체 값을 반환합니다.
					console.error("JSON parsing error:", error);
					return fallback;
				}
			};

			if (foundPost) {
				// console.log("foundPost", foundPost);

				setSelectedNumbers(JSON.parse(foundPost.cltlr));
				// 찾은 게시물로 postDetailData 상태를 업데이트합니다.
				setPostDetailData({
					doctorName: foundPost.doctorName,
					writer: foundPost.writer,
					patientName: foundPost.patientName,
					chartNumber: foundPost.chartNumber,
					responsibleLab: foundPost.responsibleLab,
					receptionDate: foundPost.receptionDate.split(" ")[0], // 날짜 형식 조정이 필요할 수 있습니다.
					deadline: foundPost.deadline,
					prosthesisType: foundPost.prosthesisType,
					attachmentUrl: foundPost.attachmentUrl,
					drawingBoard: parseJsonSafely(foundPost.drawingBoard, []),
					ponticType: foundPost.ponticType,
					trayType: foundPost.trayType,
					cltlr: parseJsonSafely(foundPost.cltlr, []),
					restorationType: parseJsonSafely(foundPost.restorationType, []),
					shade: parseJsonSafely(foundPost.shade, {}),
					chat: parseJsonSafely(foundPost.chat, []),
					notes: foundPost.notes,
					drawingBoardImage: foundPost.drawingBoardImage,
				});
			}
		} else {
			// id가 없거나 찾을 수 없는 경우, postDetailData를 초기화합니다.
			setPostDetailData({
				doctorName: "",
				writer: initialStorageDentalLabs[2].writer.name,
				patientName: "",
				chartNumber: "",
				responsibleLab: "",
				receptionDate: today,
				deadline: today,
				prosthesisType:
					prosthodonticTypes.length > 0 ? prosthodonticTypes[4].id : "",
				cltlr: [],
				restorationType: "",
				attachmentUrl: "",
				drawingBoard: {
					none: [], // 배경없음
					occlusal: [], // 상하악교합면
					frontal: [], // 정면
					buccal: [], // 구치부설측
					buccalSide: [], // 구치부단면
					lingualSide: [], // 구치부순측
				},
				shade: {
					basic: "vita classic",
					first: "",
					second: "",
					third: "",
					xnaudcmd: "",
				},
				ponticType: "",
				trayType: "",
				chat: [],
				notes: "",
				drawingBoardImage: "",
			});
		}
	}, [id, postData, prosthodonticTypes, today]);

	// console.log("현재 postID값과 일치하는 데이터", postDetailData);
	// console.log(postDetailData.writer)
	const deletePost = async () => {
		const confirm = window.confirm("정말로 삭제하시겠습니까?");

		if (confirm) {
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${accessToken}`, // Bearer 토큰 사용
					},
				};
				// Axios를 사용하여 DELETE 요청 보내기
				const response = await axios.delete(`/api/main/post/${id}`, config);
				// console.log("서버 응답:", response.data);
				navigate("/home");
				window.location.reload(); // 페이지 새로고침
			} catch (error) {
				console.error("데이터 삭제 에러:", error);
				// 에러 처리 로직
			}
		}
	};
	useEffect(() => {
		if (id === undefined) {
			if (prosthodonticTypes.length > 0) {
				setPostDetailData(prevData => ({
					...prevData,
					prosthesisType: prosthodonticTypes[4].id.toString(),
				}));
			}
		}
	}, [prosthodonticTypes]);

	//프린트 관련-------------------//
	const componentRef = useRef();

	const handdlePrint = useReactToPrint({
		//프린트하고자 하는 HTML 돔요소를 업데이트 시켜, 출력함수의 content키의 벨류로 전달
		content: () => componentRef.current,
		//documentTitle: postDetailData.chartNumber,
		//onAfterPrint: () => alert("파일 다운로드가 완료되었습니다."),
	});
	//------------------------------//

	// 이미지 첨부 삭제
	const handleDeleteImage = () => {
		setPostDetailData(prevState => ({
			...prevState,
			drawingBoardImage: "",
		}));
	};
	return (
		<section className="postMain flexCol">
			<h2>원내_검단</h2>
			<div className="postall" ref={componentRef}>
				<button className="chatBtn" onClick={toggleChat}>
					<img
						src={`${process.env.PUBLIC_URL}/images/talkIcon.png`}
						alt="의뢰서 작성"
						className="talkIcon"
					/>
				</button>
				{/* isChatOpen 상태에 따라 Chat 컴포넌트 조건부 렌더링 */}
				{isChatOpen && (
					<Chat
						onClose={toggleChat}
						handleNewMessage={handleNewMessage}
						chatMessages={postDetailData.chat}
					/>
				)}
				<div className="sizening flexCol">
					{/* 의뢰서 헤더 */}
					<div className="postHeaderSet flexRow">
						<div className="flexRow imgdockflex">
							<img
								src={`${process.env.PUBLIC_URL}/images/File_dock_light.png`}
								alt="의뢰서 작성"
								className="filedockImg"
							/>
							<h1>기공 의뢰서 작성</h1>
						</div>
						<div className="flexRow">
							<AddedInput
								label="담당의사"
								initialOptions={doctorNames}
								placeholder="새 담당의사 추가"
								onFieldChange={value => handleFieldChange("doctorName", value)} // 수정됨
								doctorName={postDetailData.doctorName}
							/>
							<AddedInput
								label="작성자"
								initialOptions={writerNames}
								placeholder="새 작성자 추가"
								onFieldChange={value => handleFieldChange("writer", value)} // 수정됨
								writer={postDetailData.writer}
							/>
						</div>
					</div>
					{/* 상단 인풋 */}
					<div className="postInputSet">
						{PostHeaderInputData.map(item => (
							<div className="postInputSize" key={item.id}>
								<PostHeaderInput
									labNames={labNames}
									item={item}
									value={postDetailData[item.id]} // postData 상태에서 해당 필드 값 가져오기
									onChange={handleFieldChange} // 상태 업데이트 함수 전달
									postID={id}
								/>
							</div>
						))}
					</div>

					{/* 기공물종류 + 치식  */}
					<div className="rlrhdanfBox">
						<h4 className="postSubTitle">기공물 종류</h4>
						<div className="flexRow postRadioSet">
							{prosthodonticTypes.map(prosthodontic => (
								<div className="postRadioSize" key={prosthodontic.id}>
									<PostRadioBox
										prosthodonticTypes={prosthodonticTypes}
										item={{ id: prosthodontic.id, label: prosthodontic.name }}
										radioType="prosthesisType"
										onChange={handleFieldChange}
										selectedValue={postDetailData.prosthesisType}
									/>
								</div>
							))}
						</div>
					</div>

					<div className="cltlrBox">
						<h4 className="postSubTitle">치식 선택</h4>
						<Cltlr
							selectedNumbers={selectedNumbers}
							setSelectedNumbers={setSelectedNumbers}
						/>
					</div>

					{/* 4-3 */}
					<div>
						<h4 className="postSubTitle">보철물 종류</h4>
						<div className="flexRow postRadioSet">
							{tmfepdlxj &&
								tmfepdlxj.map((prosthesisType, index) => (
									<div className="postRadioSize" key={index}>
										<PostmainInput
											item={{
												id: prosthesisType.id.toString(), // 아이디를 index로 설정
												label: prosthesisType.name, // 보철물 종류로 label 설정
											}}
											radioType="restorationType"
											onChange={handleCheckboxChange} // 변경된 함수를 사용
											isChecked={postDetailData.restorationType.includes(
												prosthesisType.id.toString(),
											)} // 체크 상태 결정
										/>
									</div>
								))}
							<div className="postRadioSize">
								기타 :
								<input
									className="etcInput"
									type="text"
									value={customProsthesis}
									onChange={e => setCustomProsthesis(e.target.value)}
									onKeyDown={e => {
										if (e.key === "Enter") {
											handdleAddqhcjfEtc();
										}
									}}
								/>
							</div>
						</div>
					</div>

					{/* 이미지를 위한 정렬 박스  */}
					<div className="gridsetBoxWrapper ">
						<div className="gridsetBox">
							<div className="fileShadeBox">
								{/* 4-4 */}
								<div className="postPicBox">
									<h4 className="postSubTitle">
										3D 스캔 파일 및 환자 사진{" "}
										{/* <ToggleButton isOn={isOn} setIsOn={setIsOn} /> */}
										<div className="flexRow fileUploadBox">
											<label
												htmlFor="file-upload"
												className="custom-file-upload"
												// className={isOn ? "custom-file-upload" : "uploaddisabled"}
											>
												파일 업로드
											</label>
											<button
												className="deleteSelected"
												// disabled={!isOn}
												onClick={handleRemoveSelectedFiles}
											>
												선택 삭제
											</button>
											<input
												id="file-upload"
												type="file"
												name="files[]"
												multiple
												onChange={handleFileChange}
												key={inputKey} // key 속성을 사용하여 입력 필드를 리셋
												style={{ display: "none" }} // input은 숨깁니다.
												// disabled={!isOn}
											/>
										</div>
									</h4>
									<div className="fileItem backColorDif">
										<input
											type="checkbox"
											checked={allChecked}
											onChange={e => handleAllCheck(e.target.checked)}
										/>
										<span>파일명</span>
									</div>
									<div className="fileListContainer">
										{files && files.length > 0 ? (
											files.map((file, index) => (
												<div key={index} className="fileItem">
													<input
														type="checkbox"
														checked={checkedFiles[index] || false}
														onChange={() => handleFileCheckboxChange(index)}
													/>
													<span>{file.name}</span>
												</div>
											))
										) : (
											<div className="fileCaption">
												파일 업로드를 클릭해 파일을 추가해주세요.
											</div>
										)}
									</div>
									<textarea
										name="notes"
										id="notes"
										value={postDetailData.notes}
										onChange={handleNotesChange}
									></textarea>
								</div>
								{/* 4-5 */}
								<div className="shadeBox">
									<h4 className="postSubTitle">쉐이드</h4>
									<div className="postRadioSize size4-5">
										<input
											type="radio"
											name="shade"
											value="vita classic"
											checked={postDetailData.shade.basic === "vita classic"} // 'vita classic'이면 체크
											onChange={e => handleShadeChange("basic", e.target.value)}
										/>
										<label className="firstselctshade" htmlFor="vitaClassic">
											vita classic
										</label>
										<input
											type="radio"
											name="shade"
											value="vita 3D master"
											checked={postDetailData.shade.basic === "vita 3D master"} // 'vita 3D master'이면 체크
											onChange={e => handleShadeChange("basic", e.target.value)}
										/>
										<label htmlFor="vita3DMaster">vita 3D master</label>
									</div>
									<div className="shadeSelect">
										<div>
											<label htmlFor="">절단(교합면) 1/3 색상</label>
											<select
												value={postDetailData.shade.first} // 현재 선택된 값을 지정
												onChange={e =>
													handleShadeChange("first", e.target.value)
												}
												name=""
												id=""
											>
												<option value="">선택</option>
												<option value="A1">A1</option>
												<option value="A1.5">A1.5</option>
												<option value="A2">A2</option>
												<option value="A2.5">A2.5</option>
												<option value="A3">A3</option>
												<option value="A4">A4</option>
												<option value="B1">B1</option>
												<option value="B2">B2</option>
												<option value="B3">B3</option>
												<option value="B4">B4</option>
												<option value="C1">C1</option>
												<option value="C2">C2</option>
												<option value="C3">C3</option>
												<option value="C4">C4</option>
												<option value="D2">D2</option>
												<option value="D3">D3</option>
												<option value="D4">D4</option>
											</select>
										</div>
										<div>
											<label htmlFor="">중간 1/3 색상</label>
											<select
												value={postDetailData.shade.second} // 현재 선택된 값을 지정
												onChange={e =>
													handleShadeChange("second", e.target.value)
												}
												name=""
												id=""
											>
												<option value="">선택</option>
												<option value="A1">A1</option>
												<option value="A1.5">A1.5</option>
												<option value="A2">A2</option>
												<option value="A2.5">A2.5</option>
												<option value="A3">A3</option>
												<option value="A4">A4</option>
												<option value="B1">B1</option>
												<option value="B2">B2</option>
												<option value="B3">B3</option>
												<option value="B4">B4</option>
												<option value="C1">C1</option>
												<option value="C2">C2</option>
												<option value="C3">C3</option>
												<option value="C4">C4</option>
												<option value="D2">D2</option>
												<option value="D3">D3</option>
												<option value="D4">D4</option>
											</select>
										</div>
										<div>
											<label htmlFor="">치경부 1/3 색상</label>
											<select
												value={postDetailData.shade.third} // 현재 선택된 값을 지정
												onChange={e =>
													handleShadeChange("third", e.target.value)
												}
												name=""
												id=""
											>
												<option value="">선택</option>
												<option value="없음">없음</option>
												<option value="절단면">절단면</option>
												<option value="절단 + 인접">절단 + 인접면</option>
											</select>
										</div>
										<div>
											<label htmlFor="">투명층</label>
											<select
												value={postDetailData.shade.xnaudcmd} // 현재 선택된 값을 지정
												onChange={e =>
													handleShadeChange("xnaudcmd", e.target.value)
												}
												name=""
												id=""
											>
												<option value="">선택</option>
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							{/* {!postDetailData.drawingBoardImage && (
								<div className="flexCol ClipBoardBox">
									<h4 className="postSubTitle">이미지 첨부</h4>
									<PasteEventListener
										drawingBoardImage={drawingBoardImage}
										setDrawingBoardImage={setDrawingBoardImage}
									/>
								</div>
							)}
							postDetailData.drawingBoardImage
							{postDetailData.drawingBoardImage && (
								<div className="flexCol ClipBoardBox">
									<img src={postDetailData.drawingBoardImage} alt="미리보기" />
									<button
										className="deleteSelected"
										onClick={handleDeleteImage}
									>
										이미지 삭제
									</button>
								</div>
							)} */}

							<div className=" flexCol clipBoardCanvasSection">
								<h4 className="clipBoardCanvasTitle">
									{clipBoardCanvasActive === "clipBoard"
										? "클립보드"
										: "그림판"}
									<button
										className="clipBoardCanvasBtn"
										onClick={() =>
											setClipBoardCanvasActive(
												clipBoardCanvasActive === "clipBoard"
													? "canvas"
													: "clipBoard",
											)
										}
									>
										{clipBoardCanvasActive === "clipBoard"
											? "그림판"
											: "클립보드"}
									</button>
								</h4>

								{/* {clipBoardCanvasActive === "clipBoard" ? ( */}
								<div
									className="ClipBoardBox"
									style={{
										zIndex: clipBoardCanvasActive === "clipBoard" ? 4 : 3,
									}}
								>
									<PasteEventListener
										drawingBoardImageFile={drawingBoardImageFile}
										setDrawingBoardImageFile={setDrawingBoardImageFile}
										postDrawingBoardImage={postDetailData.drawingBoardImage}
									/>
								</div>

								<div
									className="rmflavks"
									style={{
										zIndex: clipBoardCanvasActive === "rmflavks" ? 4 : 3,
									}}
								>
									<Canvas
										drawingBoard={postDetailData.drawingBoard}
										onChange={handleDrawingBoardChange}
									/>
								</div>
							</div>
						</div>
					</div>

					<div>
						<h4 className="postSubTitle">Pontic Type</h4>
						<div className="flexRow postRadioSet">
							{PonticType.map(item => (
								<div className="postRadioSize" key={item.id}>
									<PostFootRadioBox
										item={item}
										radioType="ponticType"
										onChange={handleFieldChange}
										selectedValue={postDetailData.ponticType} // 현재 선택된 Pontic Type 값을 전달
									/>
								</div>
							))}
						</div>
					</div>
					<div>
						<h4 className="postSubTitle">트레이</h4>
						<div className="flexRow postRadioSet">
							{xmfpdl.map(item => (
								<div className="postRadioSize" key={item.id}>
									<PostFootRadioBox
										item={item}
										radioType="trayType"
										onChange={handleFieldChange}
										selectedValue={postDetailData.trayType} // 현재 선택된 Tray Type 값을 전달
									/>
								</div>
							))}
						</div>
					</div>
					<h3 className="sign">검단뉴욕치과의원</h3>
				</div>
			</div>
			<div className="flexRow">
				{/* 인쇄 버튼은 id가 있을 때만 표시됩니다. */}
				{id && (
					<button className="printBTN" onClick={handdlePrint}>
						인쇄
					</button>
				)}
				<button className="saveBTN" onClick={handleSubmit}>
					{id ? "수정" : "저장"}
				</button>
				{/* 삭제 버튼은 id가 있을 때만 표시됩니다. */}
				{id && (
					<button className="delBTN" onClick={deletePost}>
						삭제
					</button>
				)}
			</div>
		</section>
	);
};

export default Post;
