import React, { useState } from "react";
import { CltlrBtn } from "../../components/Button";

const Cltlr = ({ selectedNumbers, setSelectedNumbers }) => {
	const teethGroups = {
		upperTeeth: [
			18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
		],
		babyTeethUpper: [55, 54, 53, 52, 51, 61, 62, 63, 64, 65],
		babyTeethLower: [85, 84, 83, 82, 81, 71, 72, 73, 74, 75],
		lowerTeeth: [
			48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
		],
	};

	const [isDragging, setIsDragging] = useState(false);

	const toggleSelection = number => {
		setSelectedNumbers(prev => {
			return prev.includes(number)
				? prev.filter(n => n !== number)
				: [...prev, number];
		});
	};

	const isSelected = number => selectedNumbers.includes(number);

	const handleMouseDown = number => {
		setIsDragging(true);
		toggleSelection(number); // 여기에서 버튼의 선택 상태를 토글합니다.
	};

	const handleMouseOver = number => {
		if (isDragging) {
			toggleSelection(number);
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const selectAll = () => {
		setSelectedNumbers([
			...teethGroups.upperTeeth,
			...teethGroups.babyTeethUpper,
			...teethGroups.babyTeethLower,
			...teethGroups.lowerTeeth,
		]);
	};

	const selectGroup = group => {
		setSelectedNumbers(teethGroups[group]);
	};

	const clearAll = () => {
		setSelectedNumbers([]);
	};

	return (
		<div
			className="cltlr-CltlrAll cltlr-flexCol"
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseUp}
		>
			<CltlrBtn
				numbers={teethGroups.upperTeeth}
				toggleSelection={toggleSelection}
				isSelected={isSelected}
				handleMouseDown={handleMouseDown}
				handleMouseOver={handleMouseOver}
			/>
			<div className="cltlr-flexRow">
				<div className="cltlr-textGroup">
					<div onClick={() => selectGroup("upperTeeth")}>상악</div>
					<div onClick={selectAll}>전체선택</div>
					<div onClick={() => selectGroup("lowerTeeth")}>하악</div>
				</div>
				<div>
					<CltlrBtn
						numbers={teethGroups.babyTeethUpper}
						toggleSelection={toggleSelection}
						isSelected={isSelected}
						handleMouseDown={handleMouseDown}
						handleMouseOver={handleMouseOver}
					/>
					<CltlrBtn
						numbers={teethGroups.babyTeethLower}
						toggleSelection={toggleSelection}
						isSelected={isSelected}
						handleMouseDown={handleMouseDown}
						handleMouseOver={handleMouseOver}
					/>
				</div>
				<div className="cltlr-textGroup">
					<div onClick={() => selectGroup("babyTeethUpper")}>유치상악</div>
					<div onClick={clearAll}>전체해제</div>
					<div onClick={() => selectGroup("babyTeethLower")}>유치하악</div>
				</div>
			</div>
			<CltlrBtn
				numbers={teethGroups.lowerTeeth}
				toggleSelection={toggleSelection}
				isSelected={isSelected}
				handleMouseDown={handleMouseDown}
				handleMouseOver={handleMouseOver}
			/>
		</div>
	);
};

export default Cltlr;
