import React, { useState } from "react";
import Modal from "react-modal";
import DaumPostcode from "react-daum-postcode";

export function FindAddress(props) {
	const { labelfor, span, address, detailAddress, onChange, name } = props;

	const [modalIsOpen, setIsOpen] = useState(false);

	const ModalHandle = () => {
		setIsOpen(!modalIsOpen);
	};

	const handleSelectChange = data => {
		setIsOpen(!modalIsOpen);
		console.log(data);

		onChange && onChange({ target: { name, value: data.address } });
	};

	const isInputEmpty = address.justAddress && address.justAddress.trim() === "";

	return (
		<div className="findAddress">
			<div className="joinCompanyInput">
				<div className="InputBtw">
					<label>
						{labelfor}
						{span && <span className="star">{span}</span>}
					</label>
				</div>
				<div className="inputFlex">
					<input
						className="postCode"
						type="text"
						id="sample6_postcode"
						placeholder="우편번호"
						readOnly
					/>
					<input
						className="findAddressBtn"
						type="button"
						onClick={ModalHandle}
						value="우편번호"
					/>
					<input
						className={`${span && isInputEmpty ? "warning" : ""}`}
						type="text"
						id="sample6_address"
						placeholder="주소"
						value={address}
						onChange={(onChange.HandleInputChange, onChange.HandleSubmit)}
						name={name}
						readOnly
					/>
					<input
						type="text"
						id="sample6_detailAddress"
						placeholder="상세주소"
						value={detailAddress}
						name="detailAddress"
						onChange={onChange}
					/>
				</div>
				{span && isInputEmpty && (
					<span className="waringMessage">필수 입력사항입니다.</span>
				)}
			</div>
			<Modal className="modalAddress" isOpen={modalIsOpen}>
				<button className="closeBtn" onClick={ModalHandle}>
					X
				</button>
				<DaumPostcode className="daumAddress" onComplete={handleSelectChange} />
			</Modal>
		</div>
	);
}
