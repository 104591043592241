import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import UpdateModal from "./UpdateModal";
import axios from "axios";
import NoticeModal from "./NoticeModal";
import SelectWriter from "./SelectWriter";
import WriterModal from "./WriterModal";

export function HomeHeader({ dndStatus, uniqueWriters }) {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedWriter, setSelectedWriter] = useState(null);
	const [updatedModal, setUpdatedModal] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [noticeModal, setNoticeModal] = useState(false); // 모달 상태를 관리하는 상태 변수
	const [notificationCount, setNotificationCount] = useState(
		notifications.length,
	);
	const [writerModalStatus, setWriterModalStatus] = useState(false);
	const writerModalHandle = () => {
		setWriterModalStatus(!writerModalStatus);
	};
	const clearToken = () => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		navigate("/login");
	};

	const fetchNotifications = async () => {
		try {
			const token = localStorage.getItem("accessToken");
			const response = await axios.get("/api/main/users/notified-posts", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			setNotifications(response.data);
			// console.log("들어온 데이터 ", notifications);
		} catch (error) {
			console.error("Error fetching notifications:", error);
		}
	};
	useEffect(() => {
		if (location.pathname === "/home" && !dndStatus) {
			fetchNotifications();
		}
	}, [location.pathname, noticeModal, dndStatus, notificationCount]);

	useEffect(() => {
		setNotificationCount(notifications.length); // 알림 개수 업데이트
	}, [notifications]);

	// console.log("notifications", notifications.length);
	// console.log("notificationCount", notificationCount);
	// console.log(selectedWriter);

	return (
		<nav className="homeNavSection">
			<ul className="flexRow">
				<li>
					<img
						className="DTlogoSize"
						src={`${process.env.PUBLIC_URL}/images/layout/DTLogo.svg`}
						alt="덴토탈"
					/>
				</li>
				<li className="flexRow">
					<p className="homeNavHostName">검단뉴욕치과의원</p>
					<img
						className="downImg"
						src={`${process.env.PUBLIC_URL}/images/layout/Expand_down.png`}
						alt=""
					/>
				</li>
				<li className="flexRow">
					<img
						className="memberImg"
						src={`${process.env.PUBLIC_URL}/images/layout/memberImg.png`}
						alt=""
					/>
					<p className="homeNavMember">멤버관리</p>
				</li>
			</ul>
			<ul className="flexRow">
				<li>
					<img
						src={`${process.env.PUBLIC_URL}/images/layout/Info_alt.png`}
						alt=""
						style={{
							filter:
								"sepia(100%) saturate(700%) hue-rotate(-10deg) brightness(300%)",
						}}
						onClick={() => setUpdatedModal(true)}
					/>
				</li>
				<li style={{ position: "relative" }}>
					<img
						src={`${process.env.PUBLIC_URL}/images/layout/Bell.png`}
						alt=""
						style={{
							filter:
								notifications.length > 0
									? "invert(30%) sepia(90%) saturate(7500%) hue-rotate(-10deg) brightness(100%) contrast(100%)"
									: "none",
						}}
						onClick={() => setNoticeModal(true)}
					/>

					{notificationCount > 0 && (
						<span className="notification-count">{notificationCount}</span>
					)}
				</li>
				<li onClick={clearToken}>
					<img
						src={`${process.env.PUBLIC_URL}/images/layout/Sign_out_squre.png`}
						alt=""
					/>
				</li>
				<li>
					<img
						src={`${process.env.PUBLIC_URL}/images/layout/User_cicrle.png`}
						alt=""
					/>
				</li>
				<li className="flexRow">
					<div className="profile">검단</div>
					{selectedWriter && (
						<p className="homeNavUserName" onClick={writerModalHandle}>
							{selectedWriter.name}
						</p>
					)}
				</li>
			</ul>
			{updatedModal && <UpdateModal setUpdatedModal={setUpdatedModal} />}
			{noticeModal && (
				<NoticeModal
					setNoticeModal={setNoticeModal}
					notifications={notifications}
				/>
			)}
			{!selectedWriter && (
				<SelectWriter
					setSelectedWriter={setSelectedWriter}
					uniqueWriters={uniqueWriters}
				/>
			)}
			{writerModalStatus && (
				<WriterModal
					setSelectedWriter={setSelectedWriter}
					uniqueWriters={uniqueWriters}
					writerModalHandle={writerModalHandle}
				/>
			)}
		</nav>
	);
}
