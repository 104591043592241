import React, { useState, useEffect } from "react";

const SelectWriter = ({ setSelectedWriter, uniqueWriters }) => {
	const [writers, setWriters] = useState([]);

	useEffect(() => {
		// 로컬스토리지에서 삭제된 작성자 ID 목록 가져오기
		const initialStorageDentalLabs =
			JSON.parse(localStorage.getItem("initialStorageDentalLabs")) || [];
		const deletedWriters = initialStorageDentalLabs[3]?.deleted || [];

		// uniqueWriters가 업데이트될 때 상태를 설정하고, 삭제된 작성자 필터링
		setWriters(
			uniqueWriters
				.map((name, index) => ({ id: index + 1, name }))
				.filter(writer => !deletedWriters.includes(writer.id)),
		);
	}, [uniqueWriters]);

	const handleSelect = id => {
		const writer = writers.find(writer => writer.id === id);
		setSelectedWriter(writer); // 상위 컴포넌트로 선택된 작성자 업데이트

		// 로컬스토리지에 initialStorageDentalLabs 업데이트
		const initialStorageDentalLabs =
			JSON.parse(localStorage.getItem("initialStorageDentalLabs")) || [];
		if (initialStorageDentalLabs.length < 4) {
			while (initialStorageDentalLabs.length < 4) {
				initialStorageDentalLabs.push({});
			}
		}
		initialStorageDentalLabs[2].writer = writer;
		localStorage.setItem(
			"initialStorageDentalLabs",
			JSON.stringify(initialStorageDentalLabs),
		);
	};

	const handleAdd = () => {
		const newName = prompt("작성자 이름을 입력하세요.");
		if (newName) {
			const newId = writers.length + 1;
			setWriters([...writers, { id: newId, name: newName }]);

			// 새로운 작성자를 로컬스토리지에 저장
			const initialStorageDentalLabs =
				JSON.parse(localStorage.getItem("initialStorageDentalLabs")) || [];
			if (initialStorageDentalLabs.length < 4) {
				while (initialStorageDentalLabs.length < 4) {
					initialStorageDentalLabs.push({});
				}
			}
			initialStorageDentalLabs[3].deleted =
				initialStorageDentalLabs[3].deleted || [];
			localStorage.setItem(
				"initialStorageDentalLabs",
				JSON.stringify(initialStorageDentalLabs),
			);
		}
	};

	const handleDelete = id => {
		const writerToDelete = writers.find(writer => writer.id === id);
		setWriters(writers.filter(writer => writer.id !== id));

		// 로컬스토리지에 삭제된 작성자 ID 저장
		const initialStorageDentalLabs =
			JSON.parse(localStorage.getItem("initialStorageDentalLabs")) || [];
		if (initialStorageDentalLabs.length < 4) {
			while (initialStorageDentalLabs.length < 4) {
				initialStorageDentalLabs.push({});
			}
		}
		initialStorageDentalLabs[3].deleted =
			initialStorageDentalLabs[3].deleted || [];
		initialStorageDentalLabs[3].deleted.push(id);
		localStorage.setItem(
			"initialStorageDentalLabs",
			JSON.stringify(initialStorageDentalLabs),
		);

		// 로컬스토리지에서 삭제된 작성자의 이름을 제거
		if (
			initialStorageDentalLabs[2].writer &&
			initialStorageDentalLabs[2].writer.id === id
		) {
			initialStorageDentalLabs[2].writer = {};
			localStorage.setItem(
				"initialStorageDentalLabs",
				JSON.stringify(initialStorageDentalLabs),
			);
		}
	};

	const handleReload = () => {
		// 로컬스토리지에서 initialStorageDentalLabs 값을 읽어옵니다.
		const storedLabs = JSON.parse(
			localStorage.getItem("initialStorageDentalLabs"),
		);

		// 마지막 객체의 'deleted' 배열을 비웁니다.
		storedLabs[storedLabs.length - 1].deleted = [];

		// 수정된 값을 다시 로컬스토리지에 저장합니다.
		localStorage.setItem(
			"initialStorageDentalLabs",
			JSON.stringify(storedLabs),
		);

		// 페이지를 리로드합니다.
		window.location.reload();
	};

	return (
		<div className="writer-selector">
			<button onClick={handleReload}>작성자 불러오기</button>
			<div className="writers-list">
				{writers.map(writer => (
					<div key={writer.id} className="writer-item">
						<span onClick={() => handleSelect(writer.id)}>{writer.name}</span>
						<button onClick={() => handleDelete(writer.id)}>삭제</button>
					</div>
				))}
			</div>
			<button className="addWriter" onClick={handleAdd}>
				작성자 추가
			</button>
		</div>
	);
};

export default SelectWriter;
