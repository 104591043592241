import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormBTN } from "../../components/Button";
import { JoinInput } from "../../components/InputBox";
import { JoinUserInputBox } from "../../components/InputBox";

const LoginIdFind = () => {
	const [phoneNumber, setPhoneNumber] = useState({});
	// 폼 제출 핸들러

	const HandleSubmit = e => {
		e.preventDefault();
		console.log(phoneNumber);
	};

	const HandleInputChange = e => {
		setPhoneNumber(e.target.value);
	};

	return (
		<div className="loginFind loginFindId contents">
			<h2>
				덴토탈에 가입한 연락처로
				<br />
				계정을 찾습니다.
			</h2>
			<label htmlFor="phoneNumber">
				휴대폰 번호<span className="star">*</span>
			</label>

			<div className="flex">
				<input
					type="text"
					placeholder="휴대폰 번호 4자리를 입력하세요."
					id="phoneNumber"
					onChange={HandleInputChange}
				/>
				<input
					className="codeSubmitBtn"
					type="button"
					value="인증번호 전송"
					onClick={HandleSubmit}
				/>
			</div>
			<div className="flex">
				<input type="text" placeholder="인증번호 4자리를 입력해 주세요." />
				<input className="codeCheckBtn" type="button" value="인증번호 확인" />
			</div>

			<FormBTN type="submit" detail="확인" onClick={HandleSubmit}>
				<Link to="/login"></Link>
			</FormBTN>
		</div>
	);
};

export default LoginIdFind;
