// HomeLayout.jsx

import MainHeader from "./header/MainHeader.jsx";
import MainFooter from "./footer/MainFooter.jsx";
import { Outlet } from "react-router-dom";

export function MainLayout() {
	return (
		<>
			<MainHeader />
			<Outlet />
			<MainFooter />
		</>
	);
}
