import React, { useState, useEffect } from "react";

const Timers = () => {
	// 첫 번째 타이머: 기본 카운트다운
	const [timeLeft, setTimeLeft] = useState(10);

	useEffect(() => {
		if (timeLeft === 0) return;
		const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
		return () => clearTimeout(timerId);
	}, [timeLeft]);

	// 두 번째 타이머: 인터벌 카운트
	const [intervalCount, setIntervalCount] = useState(0);

	useEffect(() => {
		const intervalId = setTimeout(
			() => setIntervalCount(intervalCount + 1),
			2000,
		);
		return () => clearTimeout(intervalId);
	}, [intervalCount]);

	// 세 번째 타이머: 시작/정지 기능
	const [activeTime, setActiveTime] = useState(30);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		if (!isActive || activeTime === 0) return;
		const activeTimerId = setTimeout(() => setActiveTime(activeTime - 1), 1000);
		return () => clearTimeout(activeTimerId);
	}, [isActive, activeTime]);

	return (
		<div>
			<div>
				<p>기본 타이머: {timeLeft} 초</p>
				{timeLeft === 0 && <p>시간이 만료되었습니다!</p>}
			</div>
			<div>
				<p>인터벌 타이머 카운트: {intervalCount}</p>
			</div>
			<div>
				<p>활성 타이머: {activeTime} 초</p>
				<button onClick={() => setIsActive(!isActive)}>
					{isActive ? "정지" : "시작"}
				</button>
			</div>
			<style jsx>{`
				p {
					color: blue;
				}
				button {
					background-color: black;
					color: white;
					padding: 10px;
					border: none;
					cursor: pointer;
				}
			`}</style>
		</div>
	);
};

export default Timers;
