import { Link } from "react-router-dom";

const LoginIdFind = () => {
	return (
		<div className="mainContents contents">
			<h2>
				치과의 모든 것,<span>덴토탈</span>
			</h2>
			<img
				src={`${process.env.PUBLIC_URL}/images/dentotal_main_logo.png`}
				alt=""
			/>
			<Link to="/login">
				<button>데모 신청하기</button>
			</Link>
		</div>
	);
};

export default LoginIdFind;
