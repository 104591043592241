const JoinSelectData = [
	{ value: "search", label: "검색(네이버, 구글, 다음)" },
	{ value: "sns", label: "SNS(페이스북, 인스타그램 등)" },
	{ value: "onlineBanner", label: "온라인 배너 광고" },
	{ value: "introduction", label: "소개 및 추천" },
	{ value: "seminars", label: "강연 및 세미나" },
];

const JoinNeedActions = [
	{ id: "employeeInformation", label: "직원정보" },
	{ id: "salary", label: "급여" },
	{ id: "management", label: "관리" },
	{ id: "vacation", label: "휴가" },
	{ id: "work(commuting)", label: "근무(출퇴근)" },
	{ id: "education", label: "교육" },
	{ id: "personnelEvaluation", label: "인사평가" },
	{ id: "ElectronicPayment", label: "전자결재" },
	{ id: "statistics", label: "통계" },
];

export default {
	JoinSelectData,
	JoinNeedActions,
};
