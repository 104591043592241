export const updates = [
	{
		id: 1,
		date: "2024-03-28 (단어 변경)",
		description: ["ㆍ 의뢰서 작성 - 쉐이드 - 중간 1/3 색상 수정"],
	},
	{
		id: 2,
		date: "2024-03-28 (기능)",
		description: [
			"ㆍ 통합페이지 기공물 건수 클릭으로 관련 페이지 즉시 이동",
			"ㆍ 새로고침 후에도 기존 페이지 유지",
			"ㆍ 기본 날짜 범위를 오늘 기준 15일 전후로 조정",
		],
	},

	{
		id: 3,
		date: "2024-04-04 (기능)",
		description: [
			"ㆍ 의뢰서 작성 시 [교정]이 Default로 선택됨",
			"ㆍ 리사이즈를 통해 Dash 페이지에서도 전체 데이터 조회 가능",
			"ㆍ 새로고침 시 선택되어있던 기간 유지",
			"ㆍ 의뢰서 작성 [그림판]기능 활성화 ",
			" └ ※ 단, 그림판 바로 밑 [저장]버튼 필수로 눌러야함 ※",
		],
	},
	{
		id: 4,
		date: "2024-04-11 (임시 기능)",
		description: [
			"ㆍ 의뢰서 작성 시 [보철물 종류] '기타' 기능 활성화",
			" └ ※ 단, 한 번만 가능  ※",
		],
	},
	{
		id: 5,
		date: "2024-04-18 (기능)",
		description: [
			"ㆍ 의뢰서 작성 시 [보철물 종류] '기타' 기능 개선",
			" └ 여러개 추가 가능",
			"ㆍ 파일첨부 기능 활성화",
			"ㆍ 그림판 버튼 간격, 수정",
		],
	},
	{
		id: 6,
		date: "2024-04-25 (기능)",
		description: [
			"ㆍ 치식 드래그 기능 추가",
			"ㆍ 치식 상,하악 유치 상,하악 선택 버튼 추가",
			"ㆍ 보철물 종류 선택 오류 수정",
		],
	},
	{
		id: 7,
		date: "2024-05-02 (기능)",
		description: [
			"ㆍ 채팅 기능 추가",
			" └ ※ 단, 의뢰서 [저장] 또는 [수정] 버튼 필수로 눌러야함 ※",
			"ㆍ 일부 절차 숨기기 가능",
		],
	},
	{
		id: 8,
		date: "2024-05-02 (디자인)",
		description: ["ㆍ 일부 반영"],
	},
	{
		id: 9,
		date: "2024-05-16 (디자인)",
		description: ["ㆍ 의뢰서 작성 반영"],
	},
	{
		id: 10,
		date: "2024-06-14",
		description: [
			"ㆍ 캘린더 배경 색상 변경 (마감일 내 완료/리메이크 : 녹색, 마감일 지난 의뢰서 : 빨간색, 마감일 일주일 전 : 주황색)",
			"ㆍ 작성자 선택 생성 (의뢰서 상태 변경 및 수정 시 해당 채팅창에 이름 남겨짐)",
			"ㆍ 상단에 변경된 내역 알림 기능 추가",
		],
	},
	{
		id: 11,
		date: "2024-06-14",
		description: [
			"ㆍ 의뢰서 작성시 상태 변경(이동) 시 해당 의뢰서 채팅방에 '000님이 상태를 변경했습니다' 로그 추가",
			"ㆍ 의뢰서 수정 시 해당 의뢰서 채팅방에 '000님이 수정했습니다' 로그 추가",
			"ㆍ 클립보드 (이미지 붙여넣기) 기능 생성 (이미지 추가 시 기존 이미지는 삭제됨)",
			"ㆍ 그림판, 클립보드 제목 클릭 시 해당 기능 보여짐",
			"ㆍ 프린트 기능 추가",
		],
	},
];
