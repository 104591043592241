import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userState } from "../../states/atoms/JoinStates";
import { FormBTN } from "../../components/Button";
import JoinPhoneModal from "./JoinPhoneModal";
import { JoinUserInputBox } from "../../components/InputBox";
import { JoinAgreement } from "./JoinAgreement";
import axios from "axios";

export function JoinUserInfo() {
	const navigate = useNavigate();

	// 회원가입 정보
	const [userInfo, setUserInfo] = useState({
		email: "",
		password: "",
		username: "",
		dateOfBirth: "",
		phoneModel: "", // 핸드폰 기종 정보를 저장할 필드
		gender: "",
		nationalityStatus: "",
		phoneNumber: "",
		termsAgreement: false, // 약관 동의 여부
	});

	// 성별 외국인 색상 관리
	const [selectedGender, setSelectedGender] = useState(null);
	const [selectedNationality, setSelectedNationality] = useState(null);

	//통신사 모달
	const [isModalOpen, setIsModalOpen] = useState(false);
	// 약관동의 이미지 상태를 관리하는 훅
	const [selectedCarrier, setSelectedCarrier] = useState("");
	// 약관동의버튼
	const [agreementModal, setAgreementModal] = useState(false);
	// 약관 동의 했는지 여부
	const [terms1, setTerms1] = useState(false);
	const [terms2, setTerms2] = useState(false);
	const [terms3, setTerms3] = useState(false);
	const [allAgreed, setAllAgreed] = useState(false);
	// 체크박스 상태가 변경될 때마다 전체 동의 상태 및 userInfo 업데이트
	useEffect(() => {
		const isAllAgreed = terms1 && terms2 && terms3;
		setAllAgreed(isAllAgreed);
		setUserInfo(prev => ({
			...prev,
			termsAgreement: isAllAgreed,
		}));
	}, [terms1, terms2, terms3]);

	// 이미지 상태
	const imageSrc = allAgreed
		? `${process.env.PUBLIC_URL}/images/login/afterCheck.svg`
		: `${process.env.PUBLIC_URL}/images/login/beforeCheck.png`;

	const handleAgreementModal = () => {
		setAgreementModal(!agreementModal);
	};

	// 모든 입력값을 userInfo 상태에 저장
	const handleInputChange = e => {
		const { name, value } = e.target;
		setUserInfo(prev => ({
			...prev,
			[name]: value,
		}));
	};
	// 성별 선택을 userInfo 상태에 저장
	const handlegenderSelect = gender => {
		setUserInfo(prev => ({
			...prev,
			gender: gender,
		}));
		setSelectedGender(gender);
	};

	// 국적 선택을 userInfo 상태에 저장
	const handleNationalitySelect = Nationality => {
		setUserInfo(prev => ({
			...prev,
			nationalityStatus: Nationality,
		}));
		setSelectedNationality(Nationality);
	};

	// 통신사 선택을 userInfo 상태에 저장
	const handleCarrierSelect = carrier => {
		setSelectedCarrier(carrier);
		setUserInfo(prev => ({
			...prev,
			phoneModel: carrier, // 통신사를 phoneModel 필드에 저장
		}));
		setIsModalOpen(false);
	};

	// 가입하기 로직
	const handleSubmit = async () => {
		// 비어있는 필드 확인
		const isAnyFieldEmpty = Object.values(userInfo).some(value => value === "");
		if (isAnyFieldEmpty) {
			alert("모든 정보를 기입해주세요.");
			return;
		}

		// 약관 동의 확인
		if (!allAgreed) {
			alert("모든 약관에 동의해야 합니다.");
			return;
		}

		// 이메일 형식 검사
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(userInfo.email)) {
			alert("올바른 이메일 주소를 입력해주세요.");
			return;
		}

		// 비밀번호 검사 (8자리 이상, 최소 1개 특수문자)
		const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
		if (!passwordRegex.test(userInfo.password)) {
			alert(
				"비밀번호는 8자리 이상이며, 최소 1개의 특수문자를 포함해야 합니다.",
			);
			return;
		}

		// 이름 검사 (2글자 이상)
		if (userInfo.username.length < 2) {
			alert("이름은 2글자 이상이어야 합니다.");
			return;
		}

		// 생년월일 검사 (YYYY-MM-DD 형식)
		const dateOfBirthRegex = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateOfBirthRegex.test(userInfo.dateOfBirth)) {
			alert("생년월일은 YYYY-MM-DD 형식으로 입력해주세요.");
			return;
		} else {
			// 형식이 맞다면 ISO 형식으로 변환
			userInfo.dateOfBirth = new Date(userInfo.dateOfBirth).toISOString();
		}

		// 휴대폰 번호 검사 (숫자만, 10~11자리)
		const phoneNumberRegex = /^\d{10,11}$/;
		if (!phoneNumberRegex.test(userInfo.phoneNumber)) {
			alert("휴대폰 번호는 숫자만 10~11자리로 입력해주세요.");
			return;
		}

		// 모든 검사를 통과했을 경우, 회원가입 요청 전송
		try {
			const response = await axios.post("/api/main/users/join", userInfo);
			console.log(response.data);
			navigate("/login");
		} catch (error) {
			console.error("회원가입 에러:", error);
		}
	};

	console.log(userInfo);

	return (
		<div className="joinUserInfoForm contents">
			<div className="flexCol">
				<div className="flexCol">
					<div className="userLoginInputBox">
						<JoinUserInputBox
							placeholder="이메일 주소를 입력해주세요"
							type="text"
							name="email"
							className="inputStyle1"
							value={userInfo.email} // 수정: value prop 추가
							imgsrc="user.svg"
							handleInputChange={handleInputChange}
						/>
						<JoinUserInputBox
							placeholder="비밀번호를 입력해주세요"
							type="password"
							name="password"
							className="inputStyle3"
							value={userInfo.password} // 수정: value prop 추가
							imgsrc="lock.svg"
							handleInputChange={handleInputChange}
						/>
					</div>
				</div>
				<div className="flexCol">
					<div className="userLoginInputBox">
						<JoinUserInputBox
							placeholder="이름"
							type="text"
							name="username"
							className="inputStyle1"
							value={userInfo.username}
							imgsrc="user.svg"
							handleInputChange={handleInputChange}
						/>
						<JoinUserInputBox
							placeholder="생년월일 8자리"
							type="text"
							name="dateOfBirth"
							className="inputStyle2"
							value={userInfo.dateOfBirth}
							imgsrc="calendar.svg"
							handleInputChange={handleInputChange}
						/>{" "}
						<div className="joinRelative">
							<img
								className="joinImgs"
								src={`${process.env.PUBLIC_URL}/images/login/calling.svg`}
								alt=""
							/>
							<button
								className="joinUserInputBox inputStyle2 phoneCompanyButton"
								type="button"
								onClick={() => setIsModalOpen(!isModalOpen)} // 현재 상태의 반대값으로 토글
							>
								{selectedCarrier || "통신사 선택"}
							</button>
							{isModalOpen && (
								<JoinPhoneModal
									isOpen={isModalOpen}
									value={userInfo.phoneModel}
									onClose={() => setIsModalOpen(false)}
									onSelectCarrier={handleCarrierSelect}
								/>
							)}
						</div>
						<div className="joinUserInputBox inputStyle2 flexRow joinInfoBtnSet">
							<div>
								<button
									className={selectedGender === "male" ? "selected" : ""}
									onClick={() => handlegenderSelect("male")}
								>
									남자
								</button>
								<button
									className={selectedGender === "female" ? "selected" : ""}
									onClick={() => handlegenderSelect("female")}
								>
									여자
								</button>
							</div>
							<div>
								<button
									className={
										selectedNationality === "domestic" ? "selected" : ""
									}
									onClick={() => handleNationalitySelect("domestic")}
								>
									내국인
								</button>
								<button
									className={
										selectedNationality === "foreigner" ? "selected" : ""
									}
									onClick={() => handleNationalitySelect("foreigner")}
								>
									외국인
								</button>
							</div>
						</div>
						<JoinUserInputBox
							placeholder="휴대폰 번호"
							type="text"
							name="phoneNumber"
							className="inputStyle3"
							value={userInfo.phoneNumber}
							imgsrc="phone.png"
							handleInputChange={handleInputChange}
						/>
					</div>
				</div>
				<div
					className={`agreementConsentCertification flexRow ${
						agreementModal ? "joinOpenAgreement" : ""
					}`}
					onClick={handleAgreementModal}
				>
					<div className="flexRow joinRelative">
						<img
							src={imageSrc}
							className="joinImgs loginImgSet"
							alt="체크 상태"
						/>

						<p className="necessaryP">[필수]</p>
						<p>인증 약관 전체동의</p>
					</div>
					<div>
						<img
							className="joinImgSize"
							src={`${process.env.PUBLIC_URL}/images/login/arrowDown.png`}
							alt=""
						/>
					</div>
				</div>
				{agreementModal && (
					<JoinAgreement
						terms1={terms1}
						setTerms1={setTerms1}
						terms2={terms2}
						setTerms2={setTerms2}
						terms3={terms3}
						setTerms3={setTerms3}
					/>
				)}
				<FormBTN detail="가입하기" onClick={handleSubmit} />
				<div className="loginPrompt">
					이미 덴토탈의 계정이 있으신가요? <Link to="/login">로그인</Link>
				</div>
			</div>
		</div>
	);
}
