// import sampleData from "../../data/sampleData.js";
import sampleData from "../../data/sampleData";
import { useEffect, useState } from "react";

export function DentalState(props) {
	// const [editableProcesses, setEditableProcesses] = useState([]);
	// const [editingOptionIndex, setEditingOptionIndex] = useState(null);
	// const [newOptionInput, setNewOptionInput] = useState(""); // 새로운 옵션 입력값 상태
	//const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태

	// useEffect(() => {
	// 	setEditableProcesses([...props.dentalProcess]);
	// }, [props.dentalProcess]);

	// console.log(editableProcesses);
	//const toggleModal = () => setIsModalOpen(!isModalOpen);

	const dentalLabMove = (label, name) => {
		if (props.selectedOption[0] === "통합") {
			props.setSelectedOption([label, name]);
		}
	};

	// 변경 시 날짜 들어감
	const handleDateChange = event => {
		const { name, value } = event.target;
		props.setDateRange(prevDateRange => ({
			...prevDateRange,
			[name]: value,
		}));
	};

	// // 옵션을 변경하는 함수
	// const handleOptionChange = (processIndex, optionIndex, newValue) => {
	// 	setEditableProcesses(prevProcesses => {
	// 		const newProcesses = [...prevProcesses];
	// 		newProcesses[processIndex].options[optionIndex] = newValue; // 해당 옵션 변경
	// 		props.setDentalProcess(newProcesses);
	// 		return newProcesses;
	// 	});
	// };

	// // 옵션을 삭제하는 함수
	// const handleOptionDelete = (processIndex, optionIndex) => {
	// 	setEditableProcesses(prevProcesses => {
	// 		// 깊은 복사를 통해 이전 상태를 복제합니다. 이는 상태 직접 수정을 방지합니다.
	// 		const newProcesses = prevProcesses.map(process => ({
	// 			...process,
	// 			options: [...process.options],
	// 		}));

	// 		// 특정 프로세스의 특정 옵션을 삭제합니다.
	// 		newProcesses[processIndex].options.splice(optionIndex, 1);
	// 		props.setDentalProcess(newProcesses);
	// 		return newProcesses;
	// 	});
	// };

	// 각 옵션에 대한 환자 수를 계산하는 함수
	const calculatePatientCountForOption = option => {
		return props.filteredPatient.filter(patient => patient.state === option)
			.length;
	};

	useEffect(() => {
		calculatePatientCountForOption();
	}, [props.filteredPatient]);

	// 수정 중인 옵션의 input 엘리먼트를 렌더링하는 함수
	// const renderEditableOptionInput = (processIndex, optionIndex, option) => {
	// 	if (editingOptionIndex === `${processIndex}-${optionIndex}`) {
	// 		return (
	// 			<input
	// 				type="text"
	// 				value={option}
	// 				onChange={e =>
	// 					handleOptionChange(processIndex, optionIndex, e.target.value)
	// 				}
	// 				onBlur={() => setEditingOptionIndex(null)}
	// 				autoFocus
	// 			/>
	// 		);
	// 	} else {
	// 		return <span>{option}</span>;
	// 	}
	// };

	// // 기존 프로세스 목록 출력
	// const renderExistingProcesses = () => {
	// 	return editableProcesses.map((item, processIndex) => (
	// 		<div key={processIndex}>
	// 			<h4>{item.label}</h4>
	// 			<ul>
	// 				{item.options.map((option, optionIndex) => (
	// 					<li key={optionIndex}>
	// 						{renderEditableOptionInput(processIndex, optionIndex, option)}
	// 						<div className="optionEditBtns">
	// 							<button
	// 								className="deleteOptionButton"
	// 								onClick={() => handleOptionDelete(processIndex, optionIndex)}
	// 							>
	// 								삭제
	// 							</button>
	// 						</div>
	// 					</li>
	// 				))}
	// 			</ul>
	// 		</div>
	// 	));
	// };

	const handleOptionHidden = optionName => {
		const storedData = JSON.parse(
			localStorage.getItem("initialStorageDentalLabs"),
		);

		const updatedData = storedData.map(item => {
			if (item.label === props.selectedOption[0]) {
				// 새로운 객체를 생성하여 기존 객체를 복사합니다.
				const newItem = { ...item };
				newItem.options = newItem.options.map(option => {
					if (option.name === props.selectedOption[1]) {
						const hiddenOption = option.hiddenProcesses.includes(optionName);
						if (hiddenOption) {
							// 해당 옵션이 이미 숨겨져 있으면 제거하여 보이게 함
							option.hiddenProcesses.splice(
								option.hiddenProcesses.indexOf(optionName),
								1,
							);
						} else {
							// 해당 옵션이 숨겨져 있지 않으면 추가하여 숨김
							option.hiddenProcesses.push(optionName);
						}
					}
					return option;
				});
				return newItem;
			}
			return item;
		});

		// 업데이트된 데이터를 로컬 스토리지에 저장
		localStorage.setItem(
			"initialStorageDentalLabs",
			JSON.stringify(updatedData),
		);
		// 해당 항목이 이미 있다면 제거하고, 없다면 추가합니다.
		if (props.hiddenOptions.includes(optionName)) {
			props.setHiddenOptions(prev => prev.filter(item => item !== optionName));
		} else {
			props.setHiddenOptions(prev => [...prev, optionName]);
		}
	};

	const DentalCurrentStateRender = () => {
		const isHidden = option => {
			if (props.hiddenOptions) {
				return props.hiddenOptions.includes(option);
			}
		};
		const toggleHidden = option => {
			handleOptionHidden(option);
		};

		return props.dentalProcess.map((process, processIndex) => (
			<li key={processIndex}>
				<h4 className="processGroup">{process.label}</h4>
				<ul>
					{process.options.map((option, optionIndex) => (
						<li
							key={optionIndex}
							style={{ opacity: isHidden(option) ? 0.5 : 1 }}
						>
							<div className="imgBox">
								<img
									src={`/images/dentalCurrentState/state${processIndex + 1}_${
										optionIndex + 1
									}.png`}
									alt={option}
								/>
							</div>
							{props.selectedOption[0] !== "통합" &&
								option !== "기공의뢰서" &&
								option !== "보류" &&
								option !== "완료" &&
								option !== "리메이크" && (
									<button onClick={() => toggleHidden(option)}>
										{isHidden(option) ? "+" : "-"}
									</button>
								)}
							<h5>{option}</h5>
							<p>
								<span className="count">
									{calculatePatientCountForOption(option)}
								</span>
								건
							</p>
						</li>
					))}
				</ul>
			</li>
		));
	};
	return (
		<>
			<div className="dentalLabNameWrap">
				<h3 className="dentalLabName">
					{props.label} - {props.name}
				</h3>
				{props.selectedOption[0] !== "통합" ? (
					<div
						className="processEditBtn"
						// onClick={toggleModal}
					>
						<img
							src={`${process.env.PUBLIC_URL}/images/dentalCurrentState/Setting_line_light.png`}
							alt="프로세스 설정"
						/>
						{/* {isModalOpen && (
						<div className="processEditBox">
							<span className="close" onClick={toggleModal}>
								&times;
							</span>
							{renderExistingProcesses()}
						</div>
					)} */}
					</div>
				) : null}
			</div>
			<div className="dentalCurrentState borderBox">
				<h3>주문/제작/배송</h3>
				<div>
					<div className="dateBox">
						<input
							type="date"
							name="startDate" // name을 firstDayOfMonth로 설정
							value={props.dateRange.startDate ? props.dateRange.startDate : ""}
							onChange={handleDateChange}
						/>
						<span>~</span>
						<input
							type="date"
							name="endDate" // name을 lastDayOfMonth로 설정
							value={props.dateRange.endDate ? props.dateRange.endDate : ""}
							onChange={handleDateChange}
						/>
					</div>
				</div>
				<ul
					className="dentalStateList"
					onClick={() => dentalLabMove(props.label, props.name)}
				>
					<DentalCurrentStateRender />
				</ul>
			</div>
		</>
	);
}
