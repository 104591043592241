import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const MainHeader = () => {
	return (
		<header className="mainHeader">
			<div className="">
				<h1>
					<Link to="/">
						<img
							src={`${process.env.PUBLIC_URL}/images/dentotal_main_logo_color
						.png`}
							alt="덴토탈"
						/>
					</Link>
				</h1>
				<nav>
					<ul>
						<li>서비스 소개</li>
						<li>가격정책</li>
						<li>바우처</li>
						<li>블로그</li>
						<li>덴토탈 소개</li>
					</ul>
				</nav>
				<div className="loginJoin">
					<button className="join">
						<Link to="/join/userinfo">회원가입</Link>
					</button>
					<button className="login">
						<Link to="/login">로그인</Link>
					</button>
				</div>
			</div>
		</header>
	);
};

export default MainHeader;
