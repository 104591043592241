import React, { useState, useEffect } from "react";

export function JoinAgreement({
	terms1,
	setTerms1,
	terms2,
	setTerms2,
	terms3,
	setTerms3,
}) {
	return (
		<div className="agreementBox ">
			<div className="flexRow agreeMentMain">
				<div className="flexRow">
					<label htmlFor="serviceTerms1" className="customCheckboxLabel">
						<input
							type="checkbox"
							id="serviceTerms1"
							className="customCheckbox"
							checked={terms1}
							onChange={() => setTerms1(!terms1)}
						/>
						<span className="checkboxCustom"></span>
					</label>{" "}
					<div className="agreeMentMain">서비스 이용약관 동의 (필수)</div>
				</div>
				<p>내용보기</p>
			</div>
			<div className="flexRow agreeMentMain">
				<div className="flexRow">
					<label htmlFor="serviceTerms2" className="customCheckboxLabel">
						<input
							type="checkbox"
							id="serviceTerms2"
							className="customCheckbox"
							checked={terms2}
							onChange={() => setTerms2(!terms2)}
						/>
						<span className="checkboxCustom"></span>
					</label>{" "}
					<div className="agreeMentMain">개인정보 수집 및 이용 동의 (필수)</div>
				</div>
				<p>내용보기</p>
			</div>{" "}
			<div className="flexRow agreeMentMain">
				<div className="flexRow">
					<label htmlFor="serviceTerms3" className="customCheckboxLabel">
						<input
							type="checkbox"
							id="serviceTerms3"
							className="customCheckbox"
							checked={terms3}
							onChange={() => setTerms3(!terms3)}
						/>
						<span className="checkboxCustom"></span>
					</label>{" "}
					<div className="agreeMentMain">마케팅 정보 수신 동의 (선택)</div>
				</div>
				<p>내용보기</p>
			</div>
		</div>
	);
}
