import React, { useState, useEffect } from "react";
import { HomeDentalLayout } from "../../layout/HomeDentalLayout.jsx";
import sampleData from "../../data/sampleData.js";

export function Home({
	selectedOption,
	setSelectedOption,
	homeData,
	prosthesisTypes,
	sidebarOpen,
	dndStatus,
	setDndStatus,
}) {
	return (
		<div>
			{/* <HomeDentalLayout selectedOption={selectedOption} /> */}
			<HomeDentalLayout
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				homeData={homeData}
				prosthesisTypes={prosthesisTypes}
				sidebarOpen={sidebarOpen}
				dndStatus={dndStatus}
				setDndStatus={setDndStatus}
			/>
		</div>
	);
}
