import { HomeHeader } from "./header/HomeHeader.jsx";
import { HomeSidebar } from "./header/HomeSidebar.jsx";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

export function HomeLayout({
	selectedOption,
	setSelectedOption,
	sidebarOpen,
	setSidebarOpen,
	homeData,
	dndStatus,
}) {
	const getUniqueWriters = data => {
		const writers = data.map(item => item.writer);
		return [...new Set(writers)];
	};

	const uniqueWriters = getUniqueWriters(homeData); // 작성자만 가져오기

	return (
		<>
			<div className={`HomeContents ${sidebarOpen ? `sidebarOpen` : ""}`}>
				<HomeHeader
					sidebarOpen={sidebarOpen}
					dndStatus={dndStatus}
					uniqueWriters={uniqueWriters}
				/>
				<HomeSidebar
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					sidebarOpen={sidebarOpen}
					setSidebarOpen={setSidebarOpen}
					homeData={homeData}
				/>
				<Outlet />
			</div>
		</>
	);
}
