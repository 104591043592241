import React, { useState, useRef, useEffect } from "react";
import "../../style/chat.scss";

// 날짜 및 시간 포매팅 함수
const formatDate = date => {
	const pad = num => (num < 10 ? `0${num}` : num);
	return (
		[date.getFullYear(), pad(date.getMonth() + 1), pad(date.getDate())].join(
			"-",
		) +
		" " +
		[pad(date.getHours()), pad(date.getMinutes()), pad(date.getSeconds())].join(
			":",
		)
	);
};

const Chat = ({ onClose, handleNewMessage, chatMessages }) => {
	// userId props를 추가합니다.
	const [messages, setMessages] = useState(chatMessages ? chatMessages : []);
	// console.log(messages);
	const [newMessage, setNewMessage] = useState("");
	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);
	const handleSendMessage = () => {
		if (!newMessage.trim()) return;
		const timestamp = new Date();
		const messageData = {
			content: newMessage, // 메시지 내용을 추가합니다.
			timestamp: formatDate(timestamp), // 생성된 시간을 추가합니다.
		};
		setMessages([...messages, messageData]);
		setNewMessage("");
		handleNewMessage(messageData);
	};

	const handleInputChange = e => {
		setNewMessage(e.target.value);
	};

	const handleKeyPress = e => {
		if (e.key === "Enter") {
			handleSendMessage();
		}
	};
	// console.log("messages", messages, "newMessage", newMessage);
	return (
		<div className="chat-modal">
			<div className="chat-header">
				<h2>채팅</h2>
				<button onClick={onClose}>닫기</button>
			</div>
			<ul className="messages-list">
				{messages &&
					messages.map((message, index) => (
						<li key={index}>
							<p>{message.content}</p>
							<span>{message.timestamp}</span>
						</li>
					))}
				<div ref={messagesEndRef} />
			</ul>
			<div className="chat-input">
				<input
					type="text"
					value={newMessage}
					onChange={handleInputChange}
					onKeyPress={handleKeyPress}
					placeholder="메시지를 입력하세요..."
				/>
				<button onClick={handleSendMessage}>보내기</button>
			</div>
		</div>
	);
};

export default Chat;
