import { useState } from "react";

export function FormBTN({ detail, onClick }) {
	return (
		<button className="FormBTN" onClick={onClick}>
			{detail}
		</button>
	);
}

export const CltlrBtn = ({
	numbers,
	toggleSelection,
	isSelected,
	handleMouseDown,
	handleMouseOver,
}) => (
	<div className="cltlr-buttonGroup">
		{numbers.map(number => (
			<button
				key={number}
				className={`cltlr-button ${isSelected(number) ? "cltlr-selected" : ""}`}
				onMouseDown={() => handleMouseDown(number)}
				onMouseOver={() => handleMouseOver(number)}
			>
				{number}
			</button>
		))}
	</div>
);

export function ToggleButton({ isOn, setIsOn }) {
	const toggleFileButton = () => {
		setIsOn(!isOn); // 토글 상태를 반전
	};

	return (
		<div
			className={`toggle-button ${isOn ? "on" : ""}`}
			onClick={toggleFileButton}
		>
			<div className="toggle-button-circle"></div>
		</div>
	);
}
