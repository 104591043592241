import axios from "axios";
import sampleData from "../data/sampleData.js";
import { DentalState } from "./home/DentalCurrentState.jsx";
import { PatientsDatas } from "./home/PatientsDatas.jsx";
import { useState, useEffect } from "react";

export function HomeDentalLayout(props) {
	////////////////////////////////
	const [initialStorageDentalLabs, setInitialStorageDentalLabs] = useState([]);
	const [hiddenOptions, setHiddenOptions] = useState([]);

	useEffect(() => {
		// 로컬 스토리지에서 데이터를 가져옵니다.
		const storedData = localStorage.getItem("initialStorageDentalLabs");
		if (storedData) {
			const parsedData = JSON.parse(storedData);
			setInitialStorageDentalLabs(parsedData);

			// 선택된 옵션과 일치하는 데이터가 있다면 숨겨진 옵션을 설정합니다.
			parsedData.forEach(item => {
				if (item.label === props.selectedOption[0]) {
					item.options.forEach(option => {
						if (option.name === props.selectedOption[1]) {
							setHiddenOptions(option.hiddenProcesses);
						}
					});
				}
			});
		}
	}, []);

	// console.log("setinitialStorageDentalLabs", initialStorageDentalLabs);
	// console.log("sethiddenOptions", hiddenOptions);
	////////////////////////////////

	// process 및 환자 데이터 설정
	const [dentalProcess, setDentalProcess] = useState([]);
	const [patientData, setPatientData] = useState([]);

	const [filteredPatientDate, setFilteredPatientDate] = useState([]);
	const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리

	const [changes, setChanges] = useState([]);
	// const [dndStatus, setDndStatus] = useState(false);
	// 날짜를 YYYY-MM-DD 포맷으로 변환하는 함수
	const formatDate = date => {
		let month = "" + (date.getMonth() + 1),
			day = "" + date.getDate(),
			year = date.getFullYear();
		if (month.length < 2) month = "0" + month; //1~9월 01~09월로 표시
		if (day.length < 2) day = "0" + day; //1~9일 01~09일로 표시
		return [year, month, day].join("-");
	};

	// 오늘 날짜 가져오기
	const today = new Date();
	// 이번 달 1일 가져오기
	const firstDayOfMonth = formatDate(
		new Date(today.getFullYear(), today.getMonth(), 1),
	);
	//오늘 날짜
	const formatToday = formatDate(today);
	// 날짜 상태 설정
	const [dateRange, setDateRange] = useState(() => {
		const savedDateRange = localStorage.getItem("dateRange");
		return savedDateRange
			? JSON.parse(savedDateRange)
			: { startDate: firstDayOfMonth, endDate: formatToday };
	});
	//dateRange 상태가 변경될 때마다 로컬 스토리지에 저장
	useEffect(() => {
		localStorage.setItem("dateRange", JSON.stringify(dateRange));
	}, [dateRange]);

	// 10시간 뒤에 dateRange 초기화
	useEffect(() => {
		const timeoutId = setTimeout(
			() => {
				setDateRange({ startDate: firstDayOfMonth, endDate: formatToday });
			},
			10 * 60 * 60 * 1000,
		); // 10시간 (10시간 * 60분 * 60초 * 1000밀리초)

		// 컴포넌트가 언마운트되거나 dateRange가 변경되면 타이머를 정리
		return () => clearTimeout(timeoutId);
	}, [firstDayOfMonth, formatToday]);

	const applyFilters = () => {
		let filteredPatientData = homeData;
		let getFilteredProcessOptions;

		//원내, 외주 구분에 따라 프로세스가 다름.
		if (props.selectedOption[0] === "원내" || props.label === "원내") {
			getFilteredProcessOptions = sampleData.DentalProcess.map(process => ({
				label: process.label,
				options: process.options.filter(opt =>
					process.label === "치과출고" ? opt !== "수거요청" : true,
				),
			}));
		} else if (props.selectedOption[0] === "외주" || props.label === "외주") {
			getFilteredProcessOptions = sampleData.DentalProcess.map(process => ({
				label: process.label,
				options:
					process.label === "기공소 수령"
						? process.options.filter(
								opt => opt !== "모델체크" && opt !== "의사확인",
							)
						: process.options,
			}));
		} else {
			getFilteredProcessOptions = sampleData.DentalProcess.map(process => ({
				label: process.label,
				options: process.options,
			}));
		}
		// // 원내, 외주 구분에 따라 프로세스가 다름.
		// if (props.selectedOption[0] === "원내" || props.label === "원내") {
		// 	getFilteredProcessOptions = sampleData.DentalProcess.map(process => {
		// 		let filteredOptions = process.options;
		// 		const matchingLab = storedDentalLabs.find(
		// 			lab =>
		// 				lab.label === "원내" &&
		// 				lab.options.some(option => option.name === props.selectedOption[1]),
		// 		);
		// 		if (matchingLab) {
		// 			filteredOptions = filteredOptions.filter(
		// 				opt =>
		// 					!matchingLab.options
		// 						.find(option => option.name === props.selectedOption[1])
		// 						.hiddenProcesses.includes(opt),
		// 			);
		// 		}
		// 		return {
		// 			label: process.label,
		// 			options:
		// 				process.label === "치과출고"
		// 					? filteredOptions.filter(opt => opt !== "수거요청")
		// 					: filteredOptions,
		// 		};
		// 	});
		// } else if (props.selectedOption[0] === "외주" || props.label === "외주") {
		// 	getFilteredProcessOptions = sampleData.DentalProcess.map(process => ({
		// 		label: process.label,
		// 		options:
		// 			process.label === "기공소 수령"
		// 				? process.options.filter(
		// 						opt => opt !== "모델체크" && opt !== "의사확인",
		// 					)
		// 				: process.options,
		// 	}));
		// } else {
		// 	getFilteredProcessOptions = sampleData.DentalProcess.map(process => ({
		// 		label: process.label,
		// 		options: process.options,
		// 	}));
		// }
		setDentalProcess(getFilteredProcessOptions);

		//통합이 아닐경우 해당 기공소에 맞는 환자데이터로 거름.
		if (props.selectedOption[1] !== "통합") {
			filteredPatientData = filteredPatientData.filter(
				patient => patient.responsibleLab === props.selectedOption[1],
			);
		}
		setPatientData(filteredPatientData);
	};

	const [homeData, setHomeData] = useState([]); // 게시글 데이터를 저장할 상태
	const [qhcjfanfData, setQhcjfanfData] = useState([]);
	useEffect(() => {
		applyFilters();
	}, [props.selectedOption]);

	const [filterdata, setFilterdata] = useState([]);
	useEffect(() => {
		const filteredData = homeData.filter(patient => {
			const receptionDate = new Date(patient.receptionDate);
			const deadline = new Date(patient.deadline);
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);

			return (
				(receptionDate >= startDate && receptionDate <= endDate) ||
				(deadline >= startDate && deadline <= endDate)
			);
		});

		setFilterdata(filteredData);
		applyFilters();
	}, [dateRange, homeData]); // 의존성 배열에 dateRange 추가

	const fetchPosts = async () => {
		try {
			// 로컬 스토리지에서 토큰 가져오기
			const token = localStorage.getItem("accessToken");

			const response = await axios.get("/api/main/post", {
				headers: {
					// 요청 헤더에 토큰 추가
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.data.posts && response.data.posts.length > 0) {
				setHomeData(response.data.posts); // 응답 데이터를 상태에 저장
				setQhcjfanfData(response.data.prosthodonticsWithTypes);
			} else {
				console.error("게시글이 비어 있습니다.");
			}
		} catch (error) {
			console.error("게시글을 가져오는 중 오류가 발생했습니다:", error);
		} finally {
			setIsLoading(false); // 데이터 로딩 완료
		}
	};

	useEffect(() => {
		fetchPosts(); // 컴포넌트 마운트 시 데이터를 불러옴
	}, []);

	useEffect(() => {
		const newFilteredPatientDate = patientData.filter(patient => {
			const receptionDate = new Date(patient.receptionDate);
			const deadline = new Date(patient.deadline);
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);

			return (
				(receptionDate >= startDate && receptionDate <= endDate) ||
				(deadline >= startDate && deadline <= endDate)
			);
		});

		setFilteredPatientDate(newFilteredPatientDate);
	}, [patientData, dateRange, props.dndStatus]);

	// 환자 데이터를 업데이트하는 함수
	const onPatientDataChange = updatedPatients => {
		setPatientData(updatedPatients);
	};

	useEffect(() => {
		const accessToken = localStorage.getItem("accessToken");
		if (!props.dndStatus) {
			// console.log(changes);
			const updatePostIdState = async () => {
				try {
					// `/api/main/home/${selectPostID}/state`에서 `/api/main/home/bulk-update`로 변경
					const response = await axios.put(
						"/api/main/home/bulk-update",
						{ updates: changes },
						{
							headers: {
								Authorization: `Bearer ${accessToken}`, // Bearer 토큰 사용
							},
						},
					);
					console.log("Update successful", response.data);
					setChanges([]); // 성공 후 변경 목록 초기화
					fetchPosts();
				} catch (error) {
					console.error("Failed to update changes", error);
				}
			};

			updatePostIdState();
		}
	}, [props.dndStatus]); // patientData가 변경될 때마다 useEffect 내부의 로직이 실행됩니다.
	// console.log(props.sidebarOpen);

	// console.log("111", homeData[0]);

	return (
		<>
			{props.selectedOption[0] === "통합" ? (
				// '통합'일 때는 DentalState 컴포넌트만 렌더링합니다.
				<>
					<DentalState
						label={"통합"}
						name={"통합"}
						dentalProcess={dentalProcess}
						dateRange={dateRange}
						filteredPatient={filteredPatientDate}
						setDateRange={setDateRange}
						setDentalProcess={setDentalProcess}
						selectedOption={props.selectedOption}
						setSelectedOption={props.setSelectedOption}
					/>
					{/* {initialStorageDentalLabs.map(lab =>
						lab.options.flat().map(option => {
							// console.log("option.name", option.name);
							const filteredResponsibleLab = patientData.filter(
								patient => patient.responsibleLab === option.name,
							);
							const filteredDate = filteredResponsibleLab.filter(patient => {
								const receptionDate = new Date(patient.receptionDate);
								const deadline = new Date(patient.deadline);
								const startDate = new Date(dateRange.startDate);
								const endDate = new Date(dateRange.endDate);

								return (
									(receptionDate >= startDate && receptionDate <= endDate) ||
									(deadline >= startDate && deadline <= endDate)
								);
							});
							let getFilteredProcessOptions;
							if (lab.label === "원내") {
								getFilteredProcessOptions = sampleData.DentalProcess.map(
									process => ({
										label: process.label,
										options: process.options.filter(opt =>
											process.label === "치과출고" ? opt !== "수거요청" : true,
										),
									}),
								);
							} else {
								getFilteredProcessOptions = sampleData.DentalProcess.map(
									process => ({
										label: process.label,
										options: process.options,
									}),
								);
							}
							return (
								<DentalState
									label={lab.label}
									name={option.name}
									dentalProcess={getFilteredProcessOptions}
									dateRange={dateRange}
									filteredPatient={filteredDate}
									setDateRange={setDateRange}
									setDentalProcess={setDentalProcess}
									selectedOption={props.selectedOption}
									setSelectedOption={props.setSelectedOption}
									hiddenOptions={hiddenOptions}
								/>
							);
						}),
					)} */}
					<PatientsDatas
						sidebarOpen={props.sidebarOpen}
						dentalProcess={dentalProcess}
						dateRange={dateRange}
						filteredPatient={filteredPatientDate}
						setPatientData={setPatientData}
						patientData={patientData}
						onPatientDataChange={onPatientDataChange}
						selectedOption={props.selectedOption}
						homeData={homeData}
						filterdata={filterdata}
						setFilterdata={setFilterdata}
						setChanges={setChanges}
						setDndStatus={props.setDndStatus}
						dndStatus={props.dndStatus} // DND 토글
						qhcjfanfData={qhcjfanfData} // 보철물 데이터
						hiddenOptions={hiddenOptions}
					/>
				</>
			) : (
				// '통합'이 아닐 때는 DentalState와 PatientsDatas 컴포넌트를 모두 렌더링합니다.
				<>
					{/* 현재 사용중인 home 화면  */}
					<DentalState
						label={props.selectedOption[0]}
						name={props.selectedOption[1]}
						dentalProcess={dentalProcess}
						dateRange={dateRange}
						filteredPatient={filteredPatientDate}
						setDateRange={setDateRange}
						setDentalProcess={setDentalProcess}
						selectedOption={props.selectedOption}
						dndStatus={props.dndStatus}
						hiddenOptions={hiddenOptions}
						setHiddenOptions={setHiddenOptions}
					/>
					<PatientsDatas
						sidebarOpen={props.sidebarOpen}
						dentalProcess={dentalProcess}
						dateRange={dateRange}
						filteredPatient={filteredPatientDate}
						setPatientData={setPatientData}
						patientData={patientData}
						onPatientDataChange={onPatientDataChange}
						selectedOption={props.selectedOption}
						homeData={homeData}
						filterdata={filterdata}
						setFilterdata={setFilterdata}
						setChanges={setChanges}
						setDndStatus={props.setDndStatus}
						dndStatus={props.dndStatus} // DND 토글
						qhcjfanfData={qhcjfanfData} // 보철물 데이터
						hiddenOptions={hiddenOptions}
					/>
				</>
			)}
		</>
	);
}
